<template>
	<component :is="componentToRender" v-bind="linkProps" :class="className" @click="click">
		<UiSmartIcon 
			v-if="prependIcon" 
			:name="prependIcon" 
			:color="prependIconColor"
			:size="20" 
			class="ui-button__icon ui-button__icon--prepend" 
		/>
		<slot v-bind="{ label }">
			{{ label }}
		</slot>
		<UiSmartIcon 
			v-if="appendIcon" 
			:name="appendIcon" 
			:color="appendIconColor"
			:size="20" 
			class="ui-button__icon ui-button__icon--append" 
		/>
	</component>
</template>

<script>
import SIZES from '../sizes.js';

export default {
	name: 'UiButton',

	props: {
		label: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: 'primary',
		},
		size: {
			type: String,
			default: SIZES.l,
		},
		prependIcon: {
			type: String,
			default: '',
		},
		prependIconColor: {
			type: String,
			default: undefined,
		},
		appendIcon: {
			type: String,
			default: '',
		},
		appendIconColor: {
			type: String,
			default: undefined,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		pending: {
			type: Boolean,
			default: false,
		},
		baseIcon: {
			type: String,
			default: '',
		},
		block: {
			type: Boolean,
			default: false,
		},
		href: {
			type: String,
			default: undefined,
		},
		/**
		 * @type {import('vue-router').RawLocation}
		 */
		to: {
			type: [String, Object],
			default: undefined,
		},
		as: {
			type: String,
			default: 'button',
		},
	},

	computed: {
		linkProps() {
			if (this.href) {
				return {
					href: this.href,
				};
			}

			if (this.to) {
				return {
					to: this.to,
				};
			}

			return {};
		},
		imgonly() {
			return (this.appendIcon || this.prependIcon) && !this.label && !this.$slots.default;
		},
		className() {
			return {
				'ui-button': true,
				'ui-button--disabled': this.disabled,
				'ui-button--pending': this.pending,
				'ui-button--block': this.block,
				'ui-button--secondary': this.type === 'secondary',
				'ui-button--primary': this.type === 'primary',
				'ui-button--link': this.type === 'link',
				'ui-button--danger': this.type === 'danger',
				'ui-button--imgonly': this.imgonly,
				'ui-button--xs': this.size === SIZES.xs,
				'ui-button--m': this.size === SIZES.m,
				'ui-button--l': this.size === SIZES.l,
			};
		},
		componentToRender() {
			if (this.href) {
				return 'a'
			}

			if (this.to) {
				return 'router-link';;
			}

			return this.as;
		}
	},

	methods: {
		click(e) {
			this.$emit('click', e);
		},
	},
};
</script>

