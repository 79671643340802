<!-- eslint-disable -->
<template>
	<div ref="tableContainer"
       class="table-container custom-scrollbar"
       :class="{
                'table-container--sticky': sticky
             }">
		<table class="ui-table">
      <thead v-if="!hasAggregateColumn || isNotSmallAggregate"
             class="ui-table__head"
             :class="{
                'ui-table__head--sticky': sticky
             }"
      >
      <tr v-if="hasGroupHeader">
        <template v-for="col of columnsGroup">
          <th v-if="col?.colspan"
              class="ui-table__heading ui-table__heading__group"
              :key="`col#${col.grope}`"
              :colspan="col.colspan">
            <span>
              {{ col.grope }}
            </span>
          </th>
          <th v-else
              :rowspan="2"
              :ref="`col__${col.name}`"
              :key="`col#${col.name}`"
              class="ui-table__heading"
              :class="colHeadClass(col)"
              :style="colHeadStyle(col)"
              :title="col.title"
          >
            <slot v-bind="{ col }" name="label">
						<span
                v-if="col.sortable"
                class="flex flex-sb"
                :class="{
								'cursor-pointer': !loading,
							}"
                @click="handleSortChange(col)"
            >
							{{ col.label }}
							<UiSmartIcon
                  class="flex-shrink-0 ml-8"
                  :name="getSortIconName(col.name)"
                  :color="getSortIconColor(col.name)"
              />
						</span>
              <span v-else>
							{{ col.label }}
						</span>
            </slot>
          </th>
        </template>
      </tr>
      <tr>
        <th v-if="showCol(col)"
            v-for="col of columns"
            :ref="`col__${col.name}`"
            :key="`col#${col.name}`"
            class="ui-table__heading"
            :class="colHeadClass(col)"
            :style="colHeadStyle(col)"
            :title="col.title"
        >
          <slot v-bind="{ col }" name="label">
			<span
            	v-if="col.sortable"
            	class="flex flex-sb"
            	:class="{
					'cursor-pointer': !loading,
				}"
            	@click="handleSortChange(col)"
            >
				{{ col.label }}
				<UiSmartIcon
            	    class="flex-shrink-0 ml-8"
            	    :name="getSortIconName(col.name)"
            	    :color="getSortIconColor(col.name)"
            	/>
			</span>
            <span v-else>
				{{ col.label }}
			</span>
          </slot>
        </th>
        <th v-if="hasGroupHeader" style="display: none"></th>
      </tr>
      </thead>
			<thead v-else-if="isSmallAggregate"
             class="ui-table__head"
             :class="{
                'ui-table__head--sticky': sticky
             }">
				<th
					v-for="col of columns"
					v-if="notInGrouped(col) && notInAggregate(col) && isColVisible(col)"
					:key="`col#${col.name}`"
					class="ui-table__heading"
					:class="colHeadClass(col)"
					:style="colHeadStyle(col)"
					:title="col.title"
				>
					<slot v-bind="{ col }" name="label">
						<span
							v-if="col.sortable"
							class="flex flex-sb"
							:class="{
								'cursor-pointer': !loading,
							}"
							@click="handleSortChange(col)"
						>
							{{ col.label }}
							<UiSmartIcon
								class="flex-shrink-0 ml-8"
								:name="getSortIconName(col.name)"
								:color="getSortIconColor(col.name)"
							/>
						</span>
						<span v-else>
							{{ col.label }}
						</span>
					</slot>
				</th>
				<th
					v-if="isSmallAggregate"
					:key="`col#${aggregateColumn.name}`"
					class="ui-table__heading"
					:class="colHeadClass(aggregateColumn)"
					:style="colHeadStyle(aggregateColumn)"
					:title="aggregateColumn.title"
				>
					<slot v-bind="{ aggregateColumn }" name="label">
						<span v-if="aggregateColumn.label">
							{{ aggregateColumn.label }}
						</span>
						<span v-else>
							{{ aggregateColumnLabels }}
						</span>
					</slot>
				</th>
			</thead>
			<tbody
				v-if="(!hasAggregateColumn || isNotSmallAggregate) && hasItems"
				class="ui-table__body"
				:class="{
					'ui-table__body--borderless': borderless,
					'ui-table__body--loading': loading,
				}"
			>
				<template v-for="(item, idx) of items">
					<tr
						:key="`row#${getKey(item, idx)}`"
						:class="getRowClass(item, idx)"
						:style="rowStyle(item)"
						@mousedown="openItemDown()"
						@mouseup="openItemUp(item, idx)"
					>
						<td
							v-if="isColVisible(col)"
							v-for="col of columns"
							:key="`cell#${col.name}`"
							class="ui-table__cell"
							:class="colClass(col, item)"
							:style="colStyle(col, item)"
							:title="colTitle(col, item)"
							@click="$emit('cellClick', item, col)"
						>
							<template v-if="hasSlot(col)">
								<slot :name="col.name" :item="item" :index="idx" />
							</template>
							<template v-else>
								{{ cellValue(col, item) }}
							</template>
						</td>
					</tr>
					<tr
						v-if="$scopedSlots['row-append']"
						:key="`row#${getKey(item, idx)}-append`"
						:class="getRowAppendClass(item, idx)"
					>
						<td
							v-if="showAppend(item, idx)"
							class="ui-table__row-append-content ui-table__cell"
							colspan="100"
						>
							<slot name="row-append" :item="item" :index="idx" />
						</td>
					</tr>
				</template>
			</tbody>
			<tbody
				v-else-if="isSmallAggregate && hasItems"
				class="ui-table__body"
				:class="{
					'ui-table__body--borderless': borderless,
					'ui-table__body--loading': loading,
				}"
			>
				<template v-for="(item, idx) of items">
					<tr
						:key="`row#${getKey(item, idx)}`"
						:class="getRowClass(item, idx)"
						:style="rowStyle(item)"
						@mousedown="openItemDown()"
						@mouseup="openItemUp(item, idx)"
					>
						<td
							v-if="notInGrouped(col) && notInAggregate(col) && isColVisible(col)"
							v-for="col of columns"
							:key="`cell#${col.name}`"
							class="ui-table__cell"
							:class="colClass(col, item)"
							:style="colStyle(col, item)"
							@click="$emit('cellClick', item, col)"
						>
							<template v-if="hasSlot(col)">
								<slot :name="col.name" :item="item" :index="idx" />
							</template>
							<template v-else>
								{{ cellValue(col, item) }}
							</template>
						</td>
						<td
							v-if="isSmallAggregate"
							:key="`cell#${aggregateColumn.name}`"
							class="ui-table__cell"
							:class="colClass(aggregateColumn, item)"
							:style="colStyle(aggregateColumn, item)"
							@click="$emit('cellClick', item, aggregateColumn)"
						>
							<div v-if="isAggregateGrope" class="ui-table__cell-aggregate">
								<p 
									v-for="col in columnsGroup"  
									v-if="col?.names"
									:key="`aggrow#${col.name || col.grope}`" 
									:class="aggregateRowClasses(col)"
								>
									<span>{{ col.grope }}</span>
									<span class="ui-table__cell-groupspan">
										<span 
											v-for="colName in col.names"
											v-if="rowHasValue(col, item, colName)"
											class="ui-table__cell-grouppart"
										>
											{{ groupCellName(colName) }}:
											{{ cellValue(col, item, colName) }}
										</span>
									</span>
									<p 
										v-for="col in aggregateColumnCels" 
										v-if="rowHasValue(col, item)" 
										:key="`aggrow#${col.name}`" 
										:class="aggregateRowClasses(col)"
									>
										{{ col.label }}:
										<template v-if="hasSlot(col)">
											<slot :name="col.name" :item="item" :index="idx" />
										</template>
										<template v-else>
											{{ cellValue(col, item) }}
										</template>
									</p>
								</p>
							</div>
							<div v-else class="ui-table__cell-aggregate">
								<p 
									v-for="col in aggregateColumnCels" 
									v-if="rowHasValue(col, item)" 
									:key="`aggrow#${col.name}`" 
									:class="aggregateRowClasses(col)"
								>
									{{ col.label }}:
									<template v-if="hasSlot(col)">
										<slot :name="col.name" :item="item" :index="idx" />
									</template>
									<template v-else>
										{{ cellValue(col, item) }}
									</template>
								</p>
							</div>
						</td>
					</tr>
					<tr
						v-if="$scopedSlots['row-append']"
						:key="`row#${getKey(item, idx)}-append`"
						:class="getRowAppendClass(item, idx)"
					>
						<td
							v-if="showAppend(item, idx)"
							class="ui-table__row-append-content ui-table__cell"
							colspan="100"
						>
							<slot name="row-append" :item="item" :index="idx" />
						</td>
					</tr>
				</template>
			</tbody>
			<tbody v-else-if="loading" class="ui-table__body">
				<tr class="ui-table__row">
					<td
						:colspan="columnCount"
						class="ui-table__cell ui-table__cell--loading"
					>
						<UiSpinner />
					</td>
				</tr>
			</tbody>
			<tbody v-else-if="!hasItems" class="ui-table__body">
				<tr class="ui-table__row">
					<td
						:colspan="columnCount"
						class="ui-table__cell ui-table__cell--loading"
					>
						{{ emptyDataLabel }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { getValue } from '@/utils/common';
import { ORDER_BY } from '@services/tables';
import { LAYOUT_SCREENS } from './tableSizes';

export default {
	name: 'UiTable',

	props: {
		/**
     * Колонки таблицы.
     * Каждый объект массива описывает параметры колонки таблицы.
     * Параметры колонки:
     * - name: string - Уникальное имя колонки
     * - label: string - Заголовок колонки
     * - sortable?: boolean = false - Признак сортировки колонки
     * - mapper?: Function(value: any): string - Функция-маппер, принимает значение, соответствующее колонке, возвращает строку для вывода в ячейку таблицы
     * - mapperItem?: Function(item: Object): string - Функция-маппер, принимает объект данных (элемент массива items), возвращает строку для вывода в ячейку таблицы
     * - align?: 'left'|'center'|'right' = 'left'
     * - alignHead?: 'left'|'center'|'right' = 'center'
     * - classHead?: string - Строка с классами для ячейки заголовка колонки
     * - styleHead?: string - Строка со стилями для ячейки заголовка колонки
     * - class?: string|function - Строка с классами для ячеек данных колонки
     * - style?: string|function - Строка со стилями для ячеек данных колонки
     *
     * Для кастомизации используется именованый шаблон, где имя слота соответствует name колонки.
     *	<template #actions="{ item, index }">
     *		<a @click="() => delete(index)" :title="`Удалить ${item.name}`">Удалить</a>
     *	</template>
     * Выводит ссылку для удаления в ячейках колонки actions. Колонка должна содержаться в массиве columns.
     *
     * @type {{name: string, label: string, ...}[]}
     */
		columns: {
			type: Array,
			required: true,
		},

		/**
     * Значения для таблицы.
     * Каждый объект является отдельной строкой таблицы.
     *
     * @type {any[]}
     */
		items: {
			type: Array,
			required: true,
		},

		/**
     * Признак загрузки данных таблицы.
     * При установке в true вместо данных таблицы (даже если они есть) отображается спиннер.
     *
     * @type {boolean}
     */
		loading: {
			type: Boolean,
			default: false,
		},

		borderless: {
			type: Boolean,
			default: false,
		},

		/** Фиксация шапки таблицы */
		sticky: {
			type: Boolean,
			default: false,
		},

		getKey: {
			type: Function,
			default: (item, idx) => idx,
		},
		/**
     * Ключ ряда, который будет открыт при загрузке
     */
		 initialRowKey: {
			type: String,
			default: null,
		},

		initialSort: {
			type: Object,
			default: () => ({ direction: ORDER_BY.ASC, by: null }),
		},
		sortBy: {
			type: String,
			default: undefined,
		},
		sortDirection: {
			type: String,
			default: undefined,
		},
		/** Что выводится при пустых items */
		emptyDataLabel: {
			type: String,
			default: 'Нет данных',
		},
		rowStyle: {
			type: Function,
			default: () => '',
		},
		/**
     * UiTableList формат, нужен чтобы задавать определенные стили фильтрам в таблице
     */
		tableListFormat: {
			type: Boolean,
			default: false,
		},
		/**
     * Признак открываемости ряда, чаще ставится там где есть append row
     */
		touchable: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			aggregateColumns: [],
			itemPressedStart: 0,
			itemPressedEnd: 0,
			selectedRowKey: this.initialRowKey,
			layoutScreen: null,
			firstScrollWidth: null,
		};
	},

	computed: {
		hasGroupHeader() {
			return this.columns.some(col => col?.group);
		},
		columnsGroup() {
			if (!this.hasGroupHeader) {
				return [];
			}
			let groups = [];
			return this.columns
				.filter(col => this.isColVisible(col))
				.map(col => {
					if (col?.group && !groups.includes(col.group)) {
						groups.push(col.group);
						return {
							colspan: this.columns.filter(colum => colum?.group === col.group).length,
							names: this.columns.filter(colum => colum?.group === col.group).map(col => col.name),
							grope: col.group
						}
					}
					if (col?.group) {
						return false;
					}

					return col;
				}).filter(grope => grope);
		},
		columnCount() {
			return this.columns.length;
		},
		hasItems() {
			return this.items?.length > 0;
		},
		isSmallLayout() {
			return this.layoutScreen === LAYOUT_SCREENS.SMALL;
		},
		aggregateColumn() {
			return this.columns.find((col) => col.name === 'aggregate' && col.columns?.length) || null;
		},
		hasAggregateColumn() {
			return this.aggregateColumn?.columns?.length > 0;
		},
		isAggregateGrope() {
			return this.aggregateColumn.aggregateType === 'grope';
		},
		aggregateColumnCels() {
			return this.columns.filter((col) => this.aggregateColumns.includes(col.name));
		},
		aggregateGropedColumnCels() {
			return this.columns.filter((col) => this.aggregateColumns.includes(col.name));
		},
		aggregateColumnLabels() {
			return this.columns.filter((col) => this.aggregateColumn?.columns.includes(col.name)).map((col) => col.label).join(', ');
		},
		isSmallAggregate() {
			return this.isSmallLayout && this.hasAggregateColumn;
		},
		isNotSmallAggregate() {
			return !this.isSmallLayout && this.hasAggregateColumn;
		},
		isNotSmallNotAggregate() {
			return !this.isSmallLayout && !this.hasAggregateColumn;
		},
	},

	watch: {
		selectedRowKey() {
			this.$emit('selectedRowKeyChange', this.selectedRowKey);
		},
	},

	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
		this.onResize();
	},

	beforeDestroy() {
		window.removeEventListener("resize", this.onResize);
	},

	methods: {
		showCol(col) {
			if (this.hasGroupHeader) {
				return this.hasColInGropeHeader(col)
			}
			return this.isColVisible(col);
		},
		hasColInGropeHeader(col) {
			return this.columnsGroup.some(group => group?.colspan && group.names.includes(col.name));
		},
		onResize() {
			/** Ждем пока таблица сформируется и определяем layoutScreen */
			if (this.loading || !this.items?.length) {
				setTimeout(() => {
					this.onResize();
				}, 250);
				return;
			}
			const tableContainer = this.$refs.tableContainer;
			/** Определяем наличие скролла */
			const hasScroll = tableContainer?.scrollWidth > tableContainer?.clientWidth;
			this.addToAggregate();
			if (!this.isSmallAggregate && !hasScroll) {
				this.aggregateColumns = [];
			}
			/** Запоминаем ширину, на котором появляется скролл в первый раз, от него мы будем отталкиваться как от нашей ширины адаптива и при динамическом изменении ширины окна */
			if (this.firstScrollWidth === null && hasScroll) this.firstScrollWidth = tableContainer.scrollWidth;
			if (hasScroll) {
				this.layoutScreen = LAYOUT_SCREENS.SMALL;
			} else if (this.firstScrollWidth !== null && tableContainer.scrollWidth >= this.firstScrollWidth) {
				this.layoutScreen = LAYOUT_SCREENS.LARGE;
			}
		},
		aggregateRowClasses(col) {
			return {
				'ui-table__aggregate-row': true,
				'ui-table__gropeaggregate-row': col.grope,
				'nw': col?.label?.includes('Статус')
			}
		},
		getRowClass(row, idx) {
			return {
				'ui-table__row--odd': idx % 2 === 1,
				'ui-table__row': true,
				'ui-table__row--touchable': this.touchable,
				'ui-table__row--selected':
          this.touchable && this.getKey(row, idx) === this.selectedRowKey,
				'ui-table__row--last':
          !this.showAppend(row, idx) && idx === this.items.length - 1,
			};
		},
		getRowAppendClass(row, idx) {
			return {
				'ui-table__row': true,
				'ui-table__row-append': true,
				'ui-table__row--odd': idx % 2 === 1,
				'ui-table__row--last':
          this.showAppend(row, idx) && idx === this.items.length - 1,
			};
		},
		showAppend(row, idx) {
			return this.touchable && this.getKey(row, idx) === this.selectedRowKey;
		},
		closeAppend() {
			this.setSelectedRowKey(null);
		},
		handleSortChange(col) {
			if (this.loading) return;

			if (!col.sortable) return;

			if (col.name === this.sortBy) {
				this.$emit(
					'sortDirectionChange',
					this.sortDirection === ORDER_BY.ASC ? ORDER_BY.DESC : ORDER_BY.ASC,
				);
			} else {
				this.$emit('sortDirectionChange', ORDER_BY.ASC);
			}
			this.$emit('sortByChange', col.name);
		},
		getSortIconName(colName) {
			if (colName !== this.sortBy) {
				return 'sort-icon';
			}

			if (this.sortDirection === ORDER_BY.ASC) {
				return 'icons-sort_up';
			}

			return 'icons-sort_down';
		},
		getSortIconColor(colName) {
			if (colName !== this.sortBy) {
				return 'rgba(255, 255, 255, 0.3)';
			}

			return 'rgba(255, 255, 255, 1)';
		},
		cellValue(column, item, colName = null) {
			let col = column; 
			if (colName) col = this.columns.find((col) => col.name === colName);
			if (col && item) {
				if (col.mapperItem) {
					return col.mapperItem(item);
				} else if (col.mapper) {
					if (col.name) {
						return col.mapper(getValue(item, col.name));
					}
				} else {
					if (col.name) {
						return getValue(item, col.name);
					}
				}
			}
			return '';
		},
		
		groupCellName(colName) {
			return this.columns.find((col) => col.name === colName).label;
		},

		hasSlot(column) {
			return !!this.$scopedSlots[column.name];
		},

		colHeadClass(column) {
			return [
				{
					'text-left': column.alignHead === 'left',
					'text-center': column.alignHead === 'center',
					'text-right': column.alignHead === 'right',
					'ui-table__heading__sub': this.hasColInGropeHeader(column),
				},
				column.classHead,
			];
		},
		colHeadStyle(column) {
			return column.styleHead ?? '';
		},
		colClass(column, item) {
			const isColNum = typeof item[column.name] === 'number' && isFinite(item[column.name]);
			return [
				{
					'text-left': column.align === 'left' || (isColNum && !column.align),
					'text-center': column.align === 'center' || (isColNum && !column.align),
					'text-right': column.align === 'right' || (isColNum && !column.align),
					'ui-table__col-ellipsis': !!column?.ellipsis,
				},
				[column.class, this.tableListFormat && column.name !== 'aggregate' && !column.flex ? 'nw' : '']
					.filter((cl) => !!cl)
					.map((cl) => (typeof cl === 'function' ? cl(item) : cl)),
			];
		},
		colTitle(column, item) {
			if (!!column?.ellipsis) {
				return item[column.name] || '';
			}
			return '';
		},
		colStyle(column, item) {
			return column.style
				? typeof column.style === 'function'
					? column.style(item)
					: column.style
				: '';
		},
		openItemDown() {
			this.itemPressedStart = new Date();
		},
		openItemUp(item, idx) {
			this.itemPressedEnd = new Date();
			const delta = (this.itemPressedEnd - this.itemPressedStart) / 100.0;

			if (delta <= 3 && this.touchable) {
				this.setSelectedRowKey(this.getKey(item, idx));
				this.$emit('open', item);
			}
		},
		setSelectedRowKey(key) {
			if (key === this.selectedRowKey) {
				this.selectedRowKey = null;
				return;
			}

			this.selectedRowKey = key;
		},

		rowHasValue(col, item, colName = null) {
			return this.cellValue(col, item, colName) && this.cellValue(col, item, colName) !== null && this.cellValue(col, item, colName) !== '';
		},

		isColVisible(col) {	
			const isHidden = typeof col.hide === 'function' ? col.hide() : col.hide;
			return !isHidden && col.name !== 'aggregate';
		},

		addToAggregate() {
			const tableContainer = this.$refs.tableContainer;
			/** Определяем наличие скролла */
			const hasScroll = tableContainer?.scrollWidth > tableContainer?.clientWidth;
			const notInAggregateColumns = this.aggregateColumn?.columns.filter((col) => !this.aggregateColumns.includes(col));
			if (hasScroll) {
				if (notInAggregateColumns?.length) this.aggregateColumns.push(notInAggregateColumns.reverse()[0]);
				setTimeout(() => {
					this.addToAggregate();
				}, 100);
			} else {
				return;
			}
		},
		notInGrouped(col) {
			let notInGrouped = true;
			this.columnsGroup.forEach((groupCol) => {
				if (groupCol?.names?.includes(col.name)) {
					notInGrouped = false;
				};
			})
			return notInGrouped;
		},
		notInAggregate(col) {
			return !this.aggregateColumns.includes(col.name);
		}
	},
};
</script>
