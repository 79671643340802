// @ts-check
import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import KC, { KC_ROLES } from '@/keycloak';
import {
	ADMIN_USERS_RIGHTS,
	INVEST_HOME,
	OPERATOR_TASKS,
} from './routes/names';
import scrollPosition from "@utils/scrollPosition";

Vue.use(Router);

const RouterObj = new Router({
	mode: 'history',
	routes: routes,
	scrollBehavior: (to, from, savedPosition) => {
		const isSameComponent = to.matched.length === 1 && from.matched.length === 1 && to.matched[0]?.components.default === from.matched[0].components.default;
		if (isSameComponent) {
			return;
		}
		return  scrollPosition.getPosition(to.name, savedPosition)
	},
});

const HOME_PAGES = {
	[KC_ROLES.ADMINISTRATOR]: { name: ADMIN_USERS_RIGHTS },
	[KC_ROLES.OPERATOR]: { name: OPERATOR_TASKS },
	[KC_ROLES.USER]: { name: INVEST_HOME },
};

RouterObj.beforeEach(async (to, from, next) => {
	const kc = KC.instance();
	const authenticated = await kc.isAuthenticated();

	// refresh token
	if (authenticated && kc.isRefreshTokenExpired()) {
		next();
		return window.location.reload();
	}

	// allow guests
	const allowGuest = to.matched.at(0).meta.allowGuest;
	if (allowGuest && !authenticated) {
		return next();
	}

	// require auth
	if (!allowGuest && !authenticated) {
		return kc.ensureToken(new URL(to.fullPath, window.location.origin).href);
	}

	// allow roles
	const userRole =
		Object.values(KC_ROLES).find((role) => kc.hasRole(role)) ?? KC_ROLES.USER;

	const roleAllowed = to.matched.at(0).meta.allowedRoles?.includes(userRole);

	if (roleAllowed) {
		scrollPosition.savePosition(from.name)

		return next();
	}

	// redirect to home page
	const resolvedHomePage = HOME_PAGES[userRole];
	return next(resolvedHomePage);
});

export default RouterObj;
