<template>
	<div class="popup__container" :class="additionalPopupClasses">
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M12.0002 21.3C16.8603 21.3 20.8002 17.3601 20.8002 12.5C20.8002 7.63984 16.8603 3.69995 12.0002 3.69995C7.14009 3.69995 3.2002 7.63984 3.2002 12.5C3.2002 17.3601 7.14009 21.3 12.0002 21.3ZM12.0002 23.2999C17.9649 23.2999 22.8002 18.4646 22.8002 12.5C22.8002 6.53528 17.9649 1.69995 12.0002 1.69995C6.03552 1.69995 1.2002 6.53528 1.2002 12.5C1.2002 18.4646 6.03552 23.2999 12.0002 23.2999Z" fill="white"/>
			<path d="M13 18.5C13 19.0523 12.5523 19.5 12 19.5C11.4477 19.5 11 19.0523 11 18.5C11 17.9477 11.4477 17.5 12 17.5C12.5523 17.5 13 17.9477 13 18.5Z" fill="white"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M12 15.6648C11.4477 15.6648 11 15.2171 11 14.6648L11 7.33479C11 6.78251 11.4477 6.33479 12 6.33479C12.5523 6.33479 13 6.78251 13 7.33479L13 14.6648C13 15.2171 12.5523 15.6648 12 15.6648Z" fill="white"/> 
		</svg>
		<div class="popup-info">
			<p v-if="title" class="popup-info__title">
				<slot name="title" v-bind="{ title }">{{ title }}</slot>
			</p>
			<p v-if="description" class="popup-info__description">{{ description }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'UiPopup',
	props: {
		type: {
			type: String, // error | success | info
			default: 'error'
		},
		title: {
			type: String,
			required: false
		},
		description: {
			type: String,
			required: false
		}
	},
	computed: {
		additionalPopupClasses() {
			switch (this.type) {
			case 'error':					
				return 'error-popup';

			case 'success':
				return 'success-popup';

			case 'info':
				return 'info-popup';

			default:
				return '';
			}
		}
	}
}
</script>
