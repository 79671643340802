export * from './SignatureBuilder';

const getCryptoPro = (() => {
	let cryptoPro = null;
	return async () => {
		if (!cryptoPro) {
			cryptoPro = await import('crypto-pro');
		}
		return cryptoPro;
	}
})();

export async function isValidSystemSetup() {
	const cryptoPro = await getCryptoPro();
	return cryptoPro.isValidSystemSetup();
}

export async function getUserCertificates() {
	const cryptoPro = await getCryptoPro();
	return cryptoPro.getUserCertificates();
}

export async function createSignature(thumbprint, messageHash) {
	const cryptoPro = await getCryptoPro();

	return cryptoPro.createDetachedSignature(
		thumbprint,
		await cryptoPro.createHash(messageHash)
	);
}
