import {
	entitySave,
	removeEntity,
	getEntityData,
	getEntityByParticipantData,
	entitySavePatch,
	entityDeletePatch,
	entityMoveToModerationPatch, entityRejectModerationPatch, entityMoveToDraftPatch, entityApplyPatch, entityAppealModerationPatch
} from '@services/entity';
import {
	getRenderedTemplateParticipant,
	deleteParticipantAccountsDraft,
	moveToDraft,
	moveToModeration,
	postParticipantAppeal
} from '@services/participant';
import {
	compareObjects,
	compareObjectsGetNewValues,
	transformToKeyPathFields,
	fromArrayToObject,
	fromObjectToArray,
} from '@utils/common';
import {OPF, OPF_CODES, ROLE_CODES} from '@configs/participants';
import { ENTITY_TYPES } from '@configs/investProject';
import { STATUS } from '@configs/participants';
import { DOCUMENT_TYPE_CODE } from '@configs/documents';
import { isDocumentSigned } from '@utils/documents';
import { pick, omit } from 'lodash';
import { fio } from '@/utils/common';
import Vue from 'vue';
import { promiseLoading } from '@/utils/loading';
import {DRAFT_ENTITY_PATCH_STATUS, MODERATION_ENTITY_PATCH_STATUS} from "@configs/entityPatchStatus";

/**
 * INVEST_PROMOTION +
 * Присоединение Инвестора ЮЛ (неизменно)
 *
 * INDIVIDUAL_PROMOTION +
 * Присоединение Инвестора, было ФЛ и ИП, стало только ФЛ
 *
 * INDIVIDUAL_ENTREPRENEUR_PROMOTION +
 * Присоединение Инвестора, только ИП, новый шаблон
 *
 * INVEST_ATTRACTING +
 * Присоединение ЛПИ, было ЮЛ и ИП, стало только ЮЛ
 *
 * INDIVIDUAL_ENTREPRENEUR_ATTRACTING +
 * Присоединение ЛПИ, только ИП, новый шаблон
 *
 * UPDATE_LEGAL_ENTITY_INVESTOR_PROMOTION
 * Изменение Инвестора ЮЛ (неизменно)
 *
 * UPDATE_INDIVIDUAL_INVESTOR_PROMOTION
 * Изменение Инвестора, было ФЛ и ИП, стало только ФЛ
 *
 * UPDATE_INDIVIDUAL_ENTREPRENEUR_INVESTOR_PROMOTION
 * Изменение Инвестора, только ИП, новый шаблон
 *
 * UPDATE_LEGAL_ENTITY_FOUNDER_PROMOTION
 * Изменение ЛПИ ЮЛ (неизменно)
 *
 * UPDATE_INDIVIDUAL_ENTREPRENEUR_FOUNDER_PROMOTION
 * Изменение ЛПИ ИП (неизменно)
 */

const TEMPLATE_CODES = {
	[ENTITY_TYPES.INDIVIDUAL + '_' + ROLE_CODES.INVESTOR]: DOCUMENT_TYPE_CODE.INDIVIDUAL_PROMOTION,
	[ENTITY_TYPES.INDIVIDUAL_ENTREPRENEUR + '_' + ROLE_CODES.INVESTOR]: DOCUMENT_TYPE_CODE.INDIVIDUAL_ENTREPRENEUR_PROMOTION,
	[ENTITY_TYPES.INDIVIDUAL_ENTREPRENEUR + '_' + ROLE_CODES.FOUNDER]: DOCUMENT_TYPE_CODE.INDIVIDUAL_ENTREPRENEUR_ATTRACTING,
	[ENTITY_TYPES.LEGAL_ENTITY + '_' + ROLE_CODES.INVESTOR]: DOCUMENT_TYPE_CODE.INVEST_PROMOTION,
	[ENTITY_TYPES.LEGAL_ENTITY + '_' + ROLE_CODES.FOUNDER]: DOCUMENT_TYPE_CODE.INVEST_ATTRACTING,
};

const TIMEOUT = 300;

const ORGANIZATION_PROPS = [
	'opf',
	'authorizedCapital',
	'sharesCount',
	'kpp',
	'ogrn',
	'name',
	'shortName',
	'address',
	'site',
	'phone',
	'okved',
];

/**
 * @type {import('vuex').Module}
 */
export default {
	namespaced: true,

	state: {
		loading: false,
		entityFilesUploading: false,
		loaderResolveEntityProfile: null,
		loaderEntityProfile: false,
		entityProfile: {},
		isEditedEntityProfile: false,
		isEditedInvestorProfile: false,
		isEditedFounderProfile: false,
		signDocInvestorProfile: null,
		signDocFounderProfile: null,
		refFormEntityProfile: null,
		refFormFounderProfile: null,
		patchRequest: null,
		patchRequestOldData: null,
		skeletonMessage: 'Получение данных анкеты...',
	},

	getters: {
		getterLoading: (state) => state.loading,
		getterSkeletonMessage: (state) => state.skeletonMessage,

		getterInvestorProfile(state) {
			return state.entityProfile.investorDto;
		},
		getterFounderProfile(state) {
			return state.entityProfile.founderDto;
		},
		getterInvestorProfileMapped(state) {
			let res = {
				...state.entityProfile,
				type: state.entityProfile.entityType,

				...state.entityProfile.investorDto,
				role: ROLE_CODES.INVESTOR,
				participantId: state.entityProfile.investorDto.participantId,

				organizationInfo: {
					...pick(state.entityProfile, [ 'inn', ...ORGANIZATION_PROPS ]),
				},

				documents: [
					...fromObjectToArray(state.entityProfile.documentDtoList),
					...fromObjectToArray(state.entityProfile.investorDto.documentsObj),
				],
			};
			res = omit(res, [
				'entityType',
				'documentsObj',
				//'investorDocumentsObj',
				//'founderDocumentsObj',
				'investorId',
				'investorDto',
				'founderDto',
				'documentDtoList',
				...ORGANIZATION_PROPS,
			]);

			// квалинвестор
			if (state.entityProfile.entityType === ENTITY_TYPES.INDIVIDUAL) {
				res.qualification = { ...res.qualification,
					selfQualified: !!res.qualification?.selfQualified,
				} 
			}

			return res;
		},
		getterFounderProfileMapped(state) {

			let res = {
				...state.entityProfile,
				type: state.entityProfile.entityType,

				...state.entityProfile.founderDto,
				role: ROLE_CODES.FOUNDER,
				participantId: state.entityProfile.founderDto?.participantId,

				organizationInfo: {
					...pick(state.entityProfile, [ 'inn', ...ORGANIZATION_PROPS]),
				},

				documents: [
					...state.entityProfile.documentDtoList,
					...fromObjectToArray(state.entityProfile.founderDto.documentsObj),
				],
			};
			res = omit(res, [
				'entityType',
				'documentsObj',
				'investorDto',
				'founderId',
				'founderDto',
				'documentDtoList',
				...ORGANIZATION_PROPS,
			]);
			return res;
		},
		getterEntityRefForm(state) {
			return state.refFormEntityProfile;
		},
		getterFounderRefForm(state) {
			return state.refFormFounderProfile;
		},
		getterEntityProfile(state) {
			return state.entityProfile;
		},
		getterEntityType(state) {
			return state.entityProfile.entityType;
		},
		getterInvestorProfileStatus(state) {
			return state.entityProfile.investorDto?.status;
		},
		getterFounderProfileStatus(state) {
			return state.entityProfile.founderDto?.status;
		},
		getterHasEntityProfile(state) {
			return Object.keys(state.entityProfile).length > 0;
		},
		getterEntityProfileIsZAO(state) {
			return state.entityProfile?.opf === OPF_CODES.ZAO || state.entityProfile?.opf === OPF_CODES.OAO;
		},
		getterInvestorProfileIsInStatusDraft(state) {
			return state.entityProfile.investorDto?.status === STATUS.DRAFT;
		},
		getterInvestorProfileIsInStatusRejected(state) {
			return state.entityProfile.investorDto?.status === STATUS.REJECTED;
		},
		getterFounderProfileIsInStatusDraft(state) {
			return state.entityProfile.founderDto?.status === STATUS.DRAFT;
		},
		getterFounderProfileIsInStatusRejected(state) {
			return state.entityProfile.founderDto?.status === STATUS.REJECTED;
		},
		getterFounderProfileIsInStatusPrepared(state) {
			return state.entityProfile.founderDto?.status === STATUS.PREPARED;
		},
		getterInvestorProfileIsInStatusPrepared(state) {
			return state.entityProfile.investorDto?.status === STATUS.PREPARED;
		},
		getterFounderProfileIsInStatusOn115fz(state) {
			return state.entityProfile.founderDto?.status === STATUS.ON_115FZ;
		},
		getterInvestorProfileIsInStatusOn115fz(state) {
			return state.entityProfile.investorDto?.status === STATUS.ON_115FZ;
		},
		getterFounderProfileIsInStatusActive(state) {
			return state.entityProfile.founderDto?.status === STATUS.ACTIVE;
		},
		getterInvestorProfileIsInStatusActive(state) {
			return state.entityProfile.investorDto?.status === STATUS.ACTIVE;
		},
		getterFounderRating(state) {
			return state.entityProfile?.rating;
		},
		getterEntityProfileIsEdited(state) {
			return state.isEditedEntityProfile;
		},
		getterInvestorProfileIsEdited(state) {
			return state.isEditedInvestorProfile;
		},
		getterFounderProfileIsEdited(state) {
			return state.isEditedFounderProfile;
		},
		getterEntityProfileIsNew(state) {
			return !state.entityProfile.entityId;
		},
		getterInvestorProfileIsNew(state) {
			return !state.entityProfile.investorDto?.participantId;
		},
		getterFounderProfileIsNew(state) {
			return !state.entityProfile.founderDto?.participantId;
		},
		getterEntityProfileId(state) {
			return state.entityProfile.entityId;
		},
		getterInvestorProfileId(state) {
			return state.entityProfile.investorDto?.participantId;
		},
		getterFounderProfileId(state) {
			return state.entityProfile.founderDto?.participantId;
		},
		getterInvestorSignDoc(state) {
			return state.signDocInvestorProfile;
		},
		getterFounderSignDoc(state) {
			return state.signDocFounderProfile;
		},
		getterInvestorHasSignDocs(state) {
			return state.signDocInvestorProfile !== null && state.signDocInvestorProfile.length;
		},
		getterFounderHasSignDocs(state) {
			return state.signDocFounderProfile !== null && state.signDocFounderProfile.length;
		},
		getterInvestorSignDocsFinish(state) {
			return state.signDocInvestorProfile
				&& state.signDocInvestorProfile.some(isDocumentSigned)
		},
		getterFounderSignDocsFinish(state) {
			return state.signDocFounderProfile
				&& state.signDocFounderProfile.some(isDocumentSigned);
		},
		getterEntityProfileFio(state) {
			return fio(
				state.entityProfile.head?.lastName || state.entityProfile.lastName,
				state.entityProfile.head?.firstName || state.entityProfile.firstName,
				state.entityProfile.head?.middleName || state.entityProfile.middleName,
			);
		},
		getterEntityFilesUploading(state) {
			return state.entityFilesUploading;
		},
		getterEntityPreviewName(state) {
			if (state.entityProfile?.shortName) {
				return state.entityProfile?.shortName;
			}

			if (state.entityProfile.entityType === ENTITY_TYPES.LEGAL_ENTITY) {
				return `${OPF[state.entityProfile.organizationOpf] ?? ''} ${state.entityProfile.organizationShortName ?? ''}`;
			}

			if (state.entityProfile.entityType === ENTITY_TYPES.INDIVIDUAL_ENTREPRENEUR) {
				let previewName = state.entityProfile.lastName;
				previewName += ` ${state.entityProfile.firstName} `;
				previewName += state.entityProfile.middleName ? `${state.entityProfile.middleName}` : '';
				return `ИП ${previewName}`;
			}

			let previewName = state.entityProfile.lastName;
			previewName += ` ${state.entityProfile.firstName} `;
			previewName += state.entityProfile.middleName ? `${state.entityProfile.middleName}` : '';
			return `${previewName}`;
		},
		getterEntityEditEnabled(state, getters) {
			const isNewInvestorProfile = getters.getterInvestorProfileIsNew || getters.getterInvestorProfileIsInStatusDraft;
			const isNewFounderProfile = getters.getterFounderProfileIsNew || getters.getterFounderProfileIsInStatusDraft;

			if (getters.getterStatusInDraftEntityPatchRequest) {
				return true;
			}

			return isNewInvestorProfile && isNewFounderProfile;
		},
		getterHasIndividualNotDraft(state) {
			return state.entityProfile?.hasIndividualNotDraft || false;
		},
		getterHasIndividualEntrepreneurNotDraft(state) {
			return state.entityProfile?.hasIndividualEntrepreneurNotDraft || false;
		},
		getterEntityPersonEditEnabled(state, getters) {
			const isIndividual = getters.getterEntityProfile.entityType === ENTITY_TYPES.INDIVIDUAL;
			const isNewInvestorProfile = getters.getterInvestorProfileIsNew || getters.getterInvestorProfileIsInStatusDraft;
			const isNewFounderProfile = getters.getterFounderProfileIsNew || getters.getterFounderProfileIsInStatusDraft;

			if (getters.getterStatusInDraftEntityPatchRequest) {
				return true;
			}

			const isDraft = isNewInvestorProfile && isNewFounderProfile;

			if (isIndividual && !getters.getterHasIndividualEntrepreneurNotDraft && isDraft) {
				return true;
			}

			if (isIndividual && getters.getterHasIndividualEntrepreneurNotDraft && isDraft) {
				return false;
			}

			if (!isIndividual && !getters.getterHasIndividualNotDraft && isDraft) {
				return true;
			}

			if (!isIndividual && getters.getterHasIndividualNotDraft && isDraft) {
				return false;
			}

			return isDraft;
		},

		getterEntityInvestorEditEnabled(state, getters) {
			if (getters.getterStatusInDraftEntityPatchRequest) {
				return true;
			}

			return getters.getterInvestorProfileIsNew || getters.getterInvestorProfileIsInStatusDraft;
		},

		getterEntityFounderEditEnabled(state, getters) {
			const isNotIndividual = getters.getterEntityType !== ENTITY_TYPES.INDIVIDUAL;
			const isNewFounderProfile = getters.getterFounderProfileIsNew || getters.getterFounderProfileIsInStatusDraft;

			if (getters.getterStatusInDraftEntityPatchRequest && isNotIndividual) {
				return true;
			}

			return isNewFounderProfile && isNotIndividual;
		},
		getterHasEntityPatchRequest(state) {
			return state.patchRequest !== null;
		},
		getterHasCreatedEntityPatchRequest(state) {
			return state.patchRequest?.entityPatchRequestId;
		},
		getterStatusInDraftEntityPatchRequest(state) {
			return state.patchRequest?.status === DRAFT_ENTITY_PATCH_STATUS;
		},
		getterStatusInModerationEntityPatchRequest(state) {
			return state.patchRequest?.status === MODERATION_ENTITY_PATCH_STATUS;
		},
		getterEntityPatchRequest(state) {
			return state.patchRequest;
		},
		getterEntityPatchRequestHistory(state) {
			return state.patchRequest?.history || [];
		},
		getterEntityPatchParticipantIdRequest(state, getters) {
			return state.patchRequest?.participantId || getters.getterInvestorProfileId || getters.getterFounderProfileId;
		},
		getterAllowedCreateEntityPatchRequest(state, getters) {
			const statusesNotAllowed = [
				STATUS.REJECTED,
				STATUS.BLOCKED,
				STATUS.ON_115FZ,
				STATUS.PREPARED,
			];
			if (statusesNotAllowed.includes(getters.getterInvestorProfileStatus) || statusesNotAllowed.includes(getters.getterFounderProfileStatus)) {
				return false;
			}

			return getters.getterFounderProfileIsInStatusActive || getters.getterInvestorProfileIsInStatusActive;
		},
		getterDocumentsInvestorPatchRequest(state) {
			return [
				...[
					...state.entityProfile?.documentDtoList,
					...state.entityProfile?.patchRequest?.documents || []
				].filter(documentDto => [
					DOCUMENT_TYPE_CODE.UPDATE_INDIVIDUAL_INVESTOR_PROMOTION,
					DOCUMENT_TYPE_CODE.UPDATE_INDIVIDUAL_ENTREPRENEUR_INVESTOR_PROMOTION,
					DOCUMENT_TYPE_CODE.UPDATE_LEGAL_ENTITY_INVESTOR_PROMOTION,
				].includes(documentDto.typeCode))
			].sort((a, b) => b.documentId - a.documentId);
		},

		getterDocumentsFounderPatchRequest(state) {
			return [
				...[
					...state.entityProfile?.documentDtoList,
					...state.entityProfile?.patchRequest?.documents || []
				].filter(documentDto => [
					DOCUMENT_TYPE_CODE.UPDATE_LEGAL_ENTITY_FOUNDER_PROMOTION,
					DOCUMENT_TYPE_CODE.UPDATE_INDIVIDUAL_ENTREPRENEUR_FOUNDER_PROMOTION,
				].includes(documentDto.typeCode))
			].sort((a, b) => b.documentId - a.documentId);
		},
		getterDocumentsEntityPatchRequest(state, getters) {
			return [
				...state.patchRequest?.documents || [],
				...state.entityProfile?.documentDtoList.filter(documentDto => [
					DOCUMENT_TYPE_CODE.UPDATE_INDIVIDUAL_INVESTOR_PROMOTION,
					DOCUMENT_TYPE_CODE.UPDATE_INDIVIDUAL_ENTREPRENEUR_INVESTOR_PROMOTION,
					DOCUMENT_TYPE_CODE.UPDATE_LEGAL_ENTITY_INVESTOR_PROMOTION,
					DOCUMENT_TYPE_CODE.UPDATE_LEGAL_ENTITY_FOUNDER_PROMOTION,
					DOCUMENT_TYPE_CODE.UPDATE_INDIVIDUAL_ENTREPRENEUR_FOUNDER_PROMOTION,
				].includes(documentDto.typeCode))
					.filter(documentDto => documentDto?.signs && documentDto?.signs.length && documentDto.signs.some(sign => sign.signerId === getters.getterEntityPatchParticipantIdRequest))
				|| []
			].sort((a, b) => b.documentId - a.documentId);
		},
		getterHasDocumentsEntityPatchRequest(state, getters) {
			return getters.getterDocumentsEntityPatchRequest.length > 0;
		},
		getterHasDocumentSingFinishEntityPatchRequest(state) {
			return state.patchRequest?.documents && state.patchRequest?.documents.some(isDocumentSigned);
		},
		getterIsInvestorSelfQualified(state) {
			return state.entityProfile?.investorDto?.qualification?.selfQualified === true;
		},
		getterChangesEntityPatchRequest(state) {
			if (state.patchRequest && state.patchRequest.status === MODERATION_ENTITY_PATCH_STATUS) {

				const prepareDocs = (entityProfile) => {

					const prepareDocArray = (docs, typeCode) => {
						return JSON.stringify(docs.filter(
							(d) => d.typeCode === typeCode,
						).map(d => {
							delete d.signs;

							return Object.keys(d).sort().map(k => d[k]).join('=>');
						}));
					}

					let documentsObj,
						investorDocumentsObj,
						founderDocumentsObj;

					documentsObj = {
						...fromArrayToObject(entityProfile.documentDtoList, 'typeCode'),
						OTHER_DOCS: prepareDocArray(entityProfile.documentDtoList, DOCUMENT_TYPE_CODE.OTHER_DOCS),
						QUAL_INVESTOR: prepareDocArray(entityProfile.documentDtoList, DOCUMENT_TYPE_CODE.QUAL_INVESTOR),
						BENEFICIARIES: prepareDocArray(entityProfile.documentDtoList, DOCUMENT_TYPE_CODE.BENEFICIARIES),
					};

					if(entityProfile.investorDto?.documentDtoList) {
						investorDocumentsObj = fromArrayToObject(
							entityProfile.investorDto.documentDtoList,
							'typeCode',
						);
					}

					if (entityProfile.founderDto?.documentDtoList) {
						founderDocumentsObj = fromArrayToObject(
							entityProfile.founderDto.documentDtoList,
							'typeCode',
						)

						founderDocumentsObj[DOCUMENT_TYPE_CODE.BANK_OPERATIONS] = prepareDocArray(entityProfile.founderDto.documentDtoList, DOCUMENT_TYPE_CODE.BANK_OPERATIONS)
					}

					return {
						...entityProfile,
						documentsObj,
						investorDto: {
							...entityProfile.investorDto,
							documentsObj: investorDocumentsObj ?? {},
						},
						founderDto: {
							...entityProfile.founderDto,
							documentsObj: founderDocumentsObj ?? {},
						},
					}
				}

				const oldData = prepareDocs(JSON.parse(JSON.stringify(state.patchRequestOldData)));

				delete oldData.founderDto?.documentDtoList;
				delete oldData.investorDto?.documentDtoList;
				delete oldData?.patchRequest;
				delete oldData?.documentDtoList;

				const patchData = prepareDocs(JSON.parse(JSON.stringify(state.patchRequest.patch)));

				delete patchData.founderDto?.documentDtoList;
				delete patchData.investorDto?.documentDtoList;
				delete patchData?.patchRequest;
				delete patchData?.documentDtoList;

				return transformToKeyPathFields(
					compareObjectsGetNewValues(oldData, patchData, ['signs'])
				);
			}

			return null;
		},
	},

	mutations: {
		mutationLoading(state, flag) {
			Vue.set(state, 'loading', flag);
		},
		mutationPatchRequest(state, patchRequest) {
			if (patchRequest) {
				state.patchRequest = {...patchRequest};

				return;
			}
			state.patchRequest = null;
		},
		mutationPatchRequestQualificationConfirmed(state, bool) {
			return state.entityProfile.investorDto.qualification.qualificationConfirmed = bool;
		},
		mutationEditedEntityProfile(state, bool) {
			state.isEditedEntityProfile = bool;
		},
		mutationEditedInvestorProfile(state, bool) {
			state.isEditedInvestorProfile = bool;
		},
		mutationEditedFounderProfile(state, bool) {
			state.isEditedInvestorProfile = bool;
		},
		mutationEntitySetRefForm(state, form) {
			state.refFormEntityProfile = form;
		},
		mutationFounderSetRefForm(state, form) {
			state.refFormFounderProfile = form;
		},
		mutationEntityProfilePutFromForm(state, entityProfile) {
			if (!entityProfile) return;

			const profileChanged = !compareObjects(entityProfile, state.entityProfile, 
				(k) => !/^(investorDto|founderDto|documentsObj)$/.test(k));
			const documentsChanged = !entityProfile.documentsObj ? false
				: !compareObjects(entityProfile.documentsObj, state.entityProfile.documentsObj);
			const investorChanged = !entityProfile.investorDto ? false
				: !compareObjects(entityProfile.investorDto, state.entityProfile.investorDto);
			const founderChanged = !entityProfile.founderDto ? false
				: !compareObjects(entityProfile.founderDto, state.entityProfile.founderDto);

			if (profileChanged || documentsChanged || investorChanged || founderChanged) {
				state.entityProfile = entityProfile;

				const isChanged = profileChanged || documentsChanged || investorChanged;

				if ((!state.entityProfile.investorDto?.status || state.entityProfile.investorDto.status === STATUS.DRAFT) && isChanged) {
					state.entityProfile.investorDto.documentsObj =
						omit(state.entityProfile.investorDto.documentsObj, Object.values(TEMPLATE_CODES));
					state.entityProfile.investorDto.documentDtoList = [
						...fromObjectToArray(state.entityProfile.investorDto.documentsObj)
					];
					state.signDocInvestorProfile = null;
				}

				if ((!state.entityProfile.founderDto?.status || state.entityProfile.founderDto.status === STATUS.DRAFT) && (isChanged || founderChanged)) {
					state.entityProfile.founderDto.documentsObj =
						omit(state.entityProfile.founderDto.documentsObj, Object.values(TEMPLATE_CODES));
					state.entityProfile.founderDto.documentDtoList = [
						...fromObjectToArray(state.entityProfile.founderDto.documentsObj)
					];
					state.signDocFounderProfile = null;
				}

				if (state.entityProfile.founderDto?.documentsObj && founderChanged && state.patchRequest) {
					state.entityProfile.founderDto.documentDtoList = [
						...fromObjectToArray(state.entityProfile.founderDto.documentsObj)
					];
				}
			}

			if (documentsChanged) {
				if (entityProfile.documentsObj) {
					Vue.set(
						state.entityProfile,
						'documentDtoList',
						fromObjectToArray(entityProfile.documentsObj),
					);
				}
			}

			if (profileChanged || documentsChanged) {
				Vue.set(state, 'isEditedEntityProfile', true);
			}
			if (investorChanged) {
				Vue.set(state, 'isEditedInvestorProfile', true);
			}
			if (founderChanged) {
				Vue.set(state, 'isEditedFounderProfile', true);
			}
		},
		mutationEntityProfile(state, entityProfile) {
			let documentsObj;
			let investorDocumentsObj;
			let founderDocumentsObj;
			if (entityProfile.documentDtoList) {
				documentsObj = {
					...fromArrayToObject(entityProfile.documentDtoList, 'typeCode'),
					OTHER_DOCS: entityProfile.documentDtoList.filter(
						(d) => d.typeCode === DOCUMENT_TYPE_CODE.OTHER_DOCS,
					),
					QUAL_INVESTOR: entityProfile.documentDtoList.filter(
						(d) => d.typeCode === DOCUMENT_TYPE_CODE.QUAL_INVESTOR,
					),
					BENEFICIARIES: entityProfile.documentDtoList.filter(
						(d) => d.typeCode === DOCUMENT_TYPE_CODE.BENEFICIARIES,
					),
				};

				if(entityProfile.investorDto?.documentDtoList) {
					investorDocumentsObj = fromArrayToObject(
						entityProfile.investorDto.documentDtoList,
						'typeCode',
					);
				}

				if (entityProfile.founderDto?.documentDtoList) {
					founderDocumentsObj = fromArrayToObject(
						entityProfile.founderDto.documentDtoList,
						'typeCode',
					)

					founderDocumentsObj[DOCUMENT_TYPE_CODE.BANK_OPERATIONS] = entityProfile.founderDto.documentDtoList.filter(
						(d) => d.typeCode === DOCUMENT_TYPE_CODE.BANK_OPERATIONS,
					)
				}

				const investorCode = entityProfile.entityType + '_' + ROLE_CODES.INVESTOR;

				const investorDocForSign = investorDocumentsObj?.[TEMPLATE_CODES[investorCode]];

				if (investorDocForSign) {
					state.signDocInvestorProfile = Array.isArray(investorDocForSign)
						? [...investorDocForSign]
						: [investorDocForSign];
				} else {
					state.signDocInvestorProfile = null;
				}

				if (entityProfile.entityType !== ENTITY_TYPES.INDIVIDUAL) {
					const founderCode = entityProfile.entityType + '_' + ROLE_CODES.FOUNDER;

					const founderDocForSign = founderDocumentsObj?.[TEMPLATE_CODES[founderCode]];
					if (founderDocForSign) {
						state.signDocFounderProfile = Array.isArray(founderDocForSign)
							? [...founderDocForSign]
							: [founderDocForSign];
					} else {
						state.signDocFounderProfile = null;
					}
				}
			}

			state.entityProfile = {
				...entityProfile,
				documentsObj,
				investorDto: {
					...entityProfile.investorDto,
					documentsObj: investorDocumentsObj ?? {},
				},
				founderDto: {
					...entityProfile.founderDto,
					documentsObj: founderDocumentsObj ?? {},
				},
			};

			if (state.entityProfile.entityType === ENTITY_TYPES.INDIVIDUAL) {
				state.entityProfile = {
					...entityProfile,
					documentsObj,
					investorDto: {
						role:  ROLE_CODES.INVESTOR,
						...entityProfile.investorDto,
						documentsObj: investorDocumentsObj ?? {},
					},
					founderDto: {},
				};
			} else {
				state.entityProfile = {
					...entityProfile,
					documentsObj,
					investorDto: {
						role:  ROLE_CODES.INVESTOR,
						...entityProfile.investorDto,
						documentsObj: investorDocumentsObj ?? {},
					},
					founderDto: {
						role:  ROLE_CODES.FOUNDER,
						...entityProfile.founderDto,
						documentsObj: founderDocumentsObj ?? {},
					},
				};
			}
		},
		mutationInvestorStatusAndClearRejectReason(state, status) {
			state.entityProfile.investorDto.status = status;
			state.entityProfile.investorDto.rejectReason = null;
		},
		mutationFounderStatusAndClearRejectReason(state, status) {
			state.entityProfile.founderDto.status = status;
			state.entityProfile.founderDto.rejectReason = null;

		},
		mutationEntityFilesUploading(state, bool) {
			state.entilyFilesUploading = bool;
		},
	},

	actions: {
		actionPromiseLoading({ commit }, promise) {
			return promiseLoading((flg) => commit('mutationLoading', flg), promise)
		},
		actionPromiseFileLoading({ commit }, promise) {
			return promiseLoading((flg) => commit('mutationEntityFilesUploading', flg), promise)
		},
		actionSetSkeletonMessage({state}, skeletonMessage) {
			state.skeletonMessage = skeletonMessage;
		},

		async actionEntityProfileSavePatch({state}) {
			state.skeletonMessage = 'Формирование заявки на изменение анкетных данных...';

			return entitySavePatch(state.entityProfile);
		},

		async actionEntityProfileDeletePatch({getters, state}) {
			state.skeletonMessage = 'Удаление заявки на изменение анкетных данных...'

			return entityDeletePatch(getters.getterEntityProfileId);
		},


		async actionEntityProfileMoveToDraftPatch({getters, state}) {
			state.skeletonMessage = 'Возвращение заявки в статус черновик...'

			return entityMoveToDraftPatch(getters.getterEntityProfileId);
		},

		async actionPatchRequestSendAppeal({ state }, appealText) {
			state.skeletonMessage = 'Подача апелляции модератору...';

			return entityAppealModerationPatch(state.entityProfile.entityId, appealText);
		},

		async actionEntityProfileApplyPatch({getters, state}) {
			state.skeletonMessage = 'Применение изменений в анкете...'

			return entityApplyPatch(getters.getterEntityProfile);
		},

		async actionEntityProfileRejectPatch({getters, state}, rejectReason) {
			state.skeletonMessage = 'Отклонение заявки на изменение анкетных данных...'

			return entityRejectModerationPatch({
				entityId: getters.getterEntityProfileId,
				rejectReason
			});
		},

		async actionEntityProfileSave({commit, state, getters}) {
			state.skeletonMessage = 'Сохранение данных анкеты...'
			state.entityProfile.investorDto.draftWaitSentToModeration = Boolean(!state.isEditedEntityProfile && !state.isEditedInvestorProfile && getters.getterInvestorHasSignDocs);
			state.entityProfile.founderDto.draftWaitSentToModeration = Boolean(!state.isEditedEntityProfile && !state.isEditedFounderProfile && getters.getterFounderHasSignDocs);

			return entitySave(state.entityProfile).then((res) => {
				commit('mutationEntityProfile', res.data);
				setTimeout(() => {
					commit('mutationEditedEntityProfile', false);
					commit('mutationEditedInvestorProfile', false);
					commit('mutationEditedFounderProfile', false);
				}, TIMEOUT)
			});
		},

		actionEntityProfileSwitchPatchRequest({state, commit }, bool) {
			commit('mutationEntityProfile', bool
				? {...state.patchRequest.patch}
				: {...state.patchRequestOldData}
			);
		},

		async actionFetchEntityById({state, commit, dispatch }, id) {
			return new Promise((resolve, reject) => {
				dispatch('actionEntityProfileClear');
				getEntityData(id)
					.then((res) => {
						const data = res.data.patchRequest?.patch || res.data;

						if (res.data.patchRequest) {
							state.patchRequest = res.data.patchRequest ? {...res.data.patchRequest} : null;
							state.patchRequestOldData = res.data;

							const patchRequestCodes = [
								DOCUMENT_TYPE_CODE.UPDATE_INDIVIDUAL_INVESTOR_PROMOTION,
								DOCUMENT_TYPE_CODE.UPDATE_INDIVIDUAL_ENTREPRENEUR_INVESTOR_PROMOTION,
								DOCUMENT_TYPE_CODE.UPDATE_LEGAL_ENTITY_INVESTOR_PROMOTION,
								DOCUMENT_TYPE_CODE.UPDATE_LEGAL_ENTITY_FOUNDER_PROMOTION,
								DOCUMENT_TYPE_CODE.UPDATE_INDIVIDUAL_ENTREPRENEUR_FOUNDER_PROMOTION,
							];

							data.documentDtoList = [
								...res.data.documentDtoList.filter(d => !patchRequestCodes.includes(d.typeCode)),
								...res.data.documentDtoList.filter(d => patchRequestCodes.includes(d.typeCode))
							];

							/** Подписи не сохраняются в Json берем из основной анкеты */

							if (data.investorDto?.documentDtoList) {
								const investorCode = data.entityType + '_' + ROLE_CODES.INVESTOR;

								const investorTemplateCode = TEMPLATE_CODES[investorCode];

								data.investorDto.documentDtoList = [
									...data.investorDto.documentDtoList.filter(d => d.typeCode !== investorTemplateCode),
									...res.data.investorDto?.documentDtoList.filter(d => d.typeCode === investorTemplateCode) || []
								];
							}

							if (data.founderDto?.documentDtoList) {

								const investorCode = data.entityType + '_' + ROLE_CODES.FOUNDER;

								const founderTemplateCode = TEMPLATE_CODES[investorCode];
								data.founderDto.documentDtoList = [
									...data.founderDto.documentDtoList.filter(d => d.typeCode !== founderTemplateCode),
									...res.data.founderDto?.documentDtoList.filter(d => d.typeCode === founderTemplateCode) || []
								];

							}
							state.patchRequest.patch = data;
						} else {
							state.patchRequest = null;
						}

						commit('mutationEntityProfile', data);
						setTimeout(() => {
							commit('mutationEditedEntityProfile', false);
							commit('mutationEditedInvestorProfile', false);
							commit('mutationEditedFounderProfile', false);
							resolve(res);
						}, TIMEOUT);
					}).catch((err) => reject(err))
			})
		},
		async actionFetchEntityByParticipantId({ commit, dispatch, state }, id) {
			state.skeletonMessage = 'Получение данных анкеты...'
			return new Promise((resolve, reject) => {
				dispatch('actionEntityProfileClear');
				getEntityByParticipantData(id)
					.then((res) => {
						commit('mutationEntityProfile', res.data);
						setTimeout(() => {
							commit('mutationEditedEntityProfile', false);
							commit('mutationEditedInvestorProfile', false);
							commit('mutationEditedFounderProfile', false);
							resolve(res);
						}, TIMEOUT)
					})
					.catch((err) => reject(err))
			})
		},
		actionEntityProfilePut({ commit }, data) {
			commit('mutationEditedEntityProfile', false);
			commit('mutationEditedInvestorProfile', false);
			commit('mutationEditedFounderProfile', false);
			commit('mutationEntityProfile', data);
		},
		actionEntityProfilePutFromForm({ commit }, data) {
			commit('mutationEntityProfilePutFromForm', data);
		},
		actionEntityProfileClear({ state }) {
			state.loaderResolveEntityProfile = null;
			state.loaderEntityProfile = false;
			state.skeletonMessage = 'Получение данных анкеты...';
			state.entityProfile = {};
			state.loaderEntityProfile = false;
			state.isEditedEntityProfile = false;
			state.isEditedInvestorProfile = false;
			state.isEditedFounderProfile = false;
			state.signDocInvestorProfile = null;
			state.signDocFounderProfile = null;
			state.refFormEntityProfile = null;
			state.refFormFounderProfile = null;
			state.patchRequest = null;
			state.patchRequestOldData = null;
		},
		async actionInvestorProfileDelete({ commit, state, dispatch }) {
			state.skeletonMessage = 'Удаление статуса инвестора...'
			const entityId = state.entityProfile.entityId;
			return dispatch('actionPromiseLoading',
				new Promise((resolve, reject) => {
					deleteParticipantAccountsDraft(state.entityProfile.investorDto.participantId)
						.then(() => {
							dispatch('userParticipant/loadEntitiesList', null, { root: true });
							dispatch('actionFetchEntityById', entityId)
								.then((res) => {
									commit('mutationEditedEntityProfile', false);
									commit('mutationEditedInvestorProfile', false);
									resolve(res);
								})
						})
						.catch((err) => reject(err))
				})
			)
		},
		async actionFounderProfileDelete({ commit, state, dispatch }) {
			state.skeletonMessage = 'Удаление статуса ЛПИ...'
			const entityId = state.entityProfile.entityId;
			return dispatch('actionPromiseLoading',
				new Promise((resolve, reject) => {
					deleteParticipantAccountsDraft(state.entityProfile.founderDto.participantId)
						.then(() => {
							dispatch('userParticipant/loadEntitiesList', null, { root: true });
							dispatch('actionFetchEntityById', entityId)
								.then((res) => {
									commit('mutationEditedEntityProfile', false);
									commit('mutationEditedFounderProfile', false);
									resolve(res);
								})
						})
						.catch((err) => reject(err))
				})
			)
		},
		async actionInvestorProfileBackToDraft({ commit, state, dispatch }) {
			state.skeletonMessage = 'Возвращение статуса инвестора в черновик...';
			return dispatch('actionPromiseLoading',
				new Promise((resolve, reject) => {
					return moveToDraft(state.entityProfile.investorDto.participantId)
						.then(() => {
							commit('mutationEditedEntityProfile', false);
							commit('mutationEditedInvestorProfile', false);
							commit('mutationInvestorStatusAndClearRejectReason', STATUS.DRAFT);
							resolve();
						})
						.catch((err) => reject(err))
				})
			)
		},
		async actionFounderProfileBackToDraft({ commit, state, dispatch }) {
			state.skeletonMessage = 'Возвращение статуса ЛПИ в черновик...';
			return dispatch('actionPromiseLoading',
				new Promise((resolve, reject) => {
					return moveToDraft(state.entityProfile.founderDto?.participantId)
						.then(() => {
							commit('mutationEditedEntityProfile', false);
							commit('mutationEditedFounderProfile', false);
							commit('mutationFounderStatusAndClearRejectReason', STATUS.DRAFT);
							resolve();
						})
						.catch((err) => reject(err))
				})
			)
		},
		async actionInvestorProfileMoveToModeration({ state }) {
			state.skeletonMessage = 'Отправка анкеты инвестора на модерацию...';
			return moveToModeration(state.entityProfile.investorDto.participantId)
		},
		async actionEntityPatchMoveToModeration({ state }) {
			state.skeletonMessage = 'Отправка анкеты на модерацию...';
			return entityMoveToModerationPatch(state.entityProfile.entityId)
		},
		async actionFounderProfileMoveToModeration({ state }) {
			state.skeletonMessage = 'Отправка анкеты ЛПИ на модерацию...';
			return moveToModeration(state.entityProfile.founderDto.participantId)
		},
		async actionInvestorProfileSendAppeal({ commit, state, dispatch }, text) {
			state.skeletonMessage = 'Подача апелляции модератору...';
			const entityId = state.entityProfile.entityId;
			return dispatch('actionPromiseLoading',
				new Promise((resolve, reject) => {
					postParticipantAppeal(state.entityProfile.investorDto.participantId, text)
						.then(() => {
							dispatch('actionFetchEntityById', entityId)
								.then((res) => {
									commit('mutationEditedEntityProfile', false);
									commit('mutationEditedInvestorProfile', false);
									resolve(res);
								})
						})
						.catch((err) => reject(err))
				})
			)
		},
		async actionFounderProfileSendAppeal({ commit, state, dispatch }, text) {
			state.skeletonMessage = 'Подача апелляции модератору...';
			const entityId = state.entityProfile.entityId;
			return dispatch('actionPromiseLoading',
				new Promise((resolve, reject) => {
					postParticipantAppeal(state.entityProfile.founderDto?.participantId, text)
						.then(() => {
							dispatch('actionFetchEntityById', entityId)
								.then((res) => {
									commit('mutationEditedEntityProfile', false);
									commit('mutationEditedFounderProfile', false);
									resolve(res);
								})
						})
						.catch((err) => reject(err))
				})
			)
		},
		async actionInvestorCreateDocForSign({ commit, state }, participantId) {
			state.skeletonMessage = 'Формирование документов для подписания...';
			const res = await getRenderedTemplateParticipant(participantId);
			if (!state.entityProfile.investorDto.documentDtoList) {
				state.entityProfile.investorDto.documentDtoList = [];
			}
			state.entityProfile.investorDto.documentDtoList.push(res.data);
			commit('mutationEntityProfile', state.entityProfile);
			commit('mutationEditedEntityProfile', false);
			commit('mutationEditedInvestorProfile', false);
			return res;
		},
		async actionFounderCreateDocForSign({ commit, state }, participantId) {
			state.skeletonMessage = 'Формирование документов для подписания...';
			const res = await getRenderedTemplateParticipant(participantId);
			if (!state.entityProfile.founderDto?.documentDtoList) {
				state.entityProfile.founderDto.documentDtoList = [];
			}
			state.entityProfile.founderDto?.documentDtoList.push(res.data);
			commit('mutationEntityProfile', state.entityProfile);
			commit('mutationEditedEntityProfile', false);
			commit('mutationEditedFounderProfile', false);
			return res;
		},
		async actionRemoveEntity({dispatch, state}) {
			state.skeletonMessage = 'Удаление анкеты...';
			return dispatch('actionPromiseLoading',
				new Promise((resolve, reject) => {
					return removeEntity(state.entityProfile.entityId)
						.then(async () => {
							await dispatch('actionEntityProfileClear');
							await dispatch('userParticipant/loadEntitiesList', null, { root: true });
							resolve();
						})
						.catch((err) => reject(err))
				})
			)
		},
		actionFounderProfileSetEdited({ commit }, bool) {
			commit('mutationEditedFounderProfile', bool);
		},
		actionEntitySetRefForm({ commit }, form) {
			commit('mutationEntitySetRefForm', form);
		},
		actionFounderSetRefForm({ commit }, form) {
			commit('mutationFounderSetRefForm', form);
		},
	},

};
