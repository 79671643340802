var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('UiModal', {
    attrs: {
      "show": _vm.isVisible,
      "width": _vm.width,
      "height": _vm.height,
      "isNotify": _vm.isNotify,
      "skeleton-loading": _vm.skeletonLoading,
      "skeleton-message": _vm.skeletonMessage,
      "closeByClickOutside": _vm.closeByClickOutside,
      "styles": "overflow:hidden;display:flex"
    },
    on: {
      "show": _vm.closeModal
    }
  }, [_c('div', {
    staticClass: "dialog-content"
  }, [_c('h2', {
    staticClass: "dialog-content__header"
  }, [_c('div', {
    staticStyle: {
      "width": "90%"
    }
  }, [_vm._t("header", function () {
    return [_vm._v(_vm._s(_vm.title))];
  }, null, {
    resolve: _vm.clickButtonHandler
  })], 2), _c('button', {
    staticClass: "dialog-content__close-icon",
    attrs: {
      "tabindex": "-1"
    },
    on: {
      "click": _vm.closeModal
    }
  })]), _c('div', {
    ref: "dialogMainContent",
    class: _vm.mainContentClasses
  }, [_vm._t("default", null, null, {
    resolve: _vm.clickButtonHandler
  })], 2), !_vm.withoutFooter ? _c('div', {
    class: {
      'dialog-content__footer': true,
      'footer-left': _vm.footerLeft,
      'footer-span': _vm.footerSpan
    }
  }, [_vm._t("footer-start"), _c('div', {
    staticClass: "dialog-content__footer-error"
  }, [_vm._v(" " + _vm._s(_vm.validatorMessage) + " ")]), _vm._t("footer"), _c('div', {
    staticClass: "dialog-content__footer-buttons"
  }, _vm._l(_vm.dialogButtons, function (btn, idx) {
    return _c('UiButton', _vm._b({
      key: ("btn#" + idx),
      staticClass: "dialog-content__footer-button",
      on: {
        "click": function ($event) {
          return _vm.clickButtonHandler(("" + (btn.name)));
        }
      }
    }, 'UiButton', Object.assign({
      type: 'secondary'
    }, btn), false));
  }), 1)], 2) : _vm._e(), _vm.footerSpan ? _c('span', {
    staticClass: "dialog-content__footer-span"
  }, [_vm._v(_vm._s(_vm.footerSpan))]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }