import Requests from '@utils/Requests.js';
import api from '@configs/api.js';

export const CONTUR_SETTINGS_CODES = {
	PASSPORT_CHECK: 'PASSPORT_CHECK',
	ENABLE: 'ENABLE',
}

export function editSettings(data) {
	return Requests.requestWrapper(
		Requests.putRequest(`/${api.url}settings/common`, data, {}, true),
	);
}

export async function getSettings(param) {
	if (typeof param === 'string') {
		const result = await Requests.requestWrapper(
			Requests.getRequest(`/${api.url}settings/common?property=${param}`, {}),
		);
		return result.data;
	} else {
		const stringParams = param.map((el) => `property=${el}&`).join('');
		const result = await Requests.requestWrapper(
			Requests.getRequest(`/${api.url}settings/common?${stringParams}`, {}),
		);
		return result.data;
	}
}

/**
 *
 * @returns {Promise<{
 *   phone: string,
 *   email: string,
 *   mapsHtml: string,
 *   address: string,
 *   description: string,
 *   telegramLink: string,
 *   instagramLink: string,
 *   vkLink: string,
 * }>}
 */
export function getCotactSettings() {
	const SETTINGS_KEYS = [
		'phone',
		'email',
		'mapsHtml',
		'address',
		'description',
		'telegramLink',
		'instagramLink',
		'vkLink',
	];

	return getSettings(SETTINGS_KEYS);
}

/**
 * 
 * @returns {Promise<{
 *   footer: String,
 * }>}
 */
export function getFooterSettings() {
	const SETTINGS_KEYS = [
		'footer',
	];

	return getSettings(SETTINGS_KEYS).then((res) => JSON.parse(res.footer));
}

export function editFooterSettings(data) {
	return editSettings({ footer: JSON.stringify(data) });
}

export function getEncyclopediaSettings() {
	const SETTINGS_KEYS = 'encyclopedia';

	return getSettings(SETTINGS_KEYS).then((res) => JSON.parse(res.encyclopedia));
}

export function editEncyclopediaSettings(articles) {
	return editSettings({ encyclopedia: JSON.stringify(articles) });
}

export function getStandardDocs() {
	const SETTINGS_KEYS = 'standardDocs';

	return getSettings(SETTINGS_KEYS).then((res) => JSON.parse(res.standardDocs));
}

export function editStandardDocs(docs) {
	return editSettings({ standardDocs: JSON.stringify(docs) });
}

export function getDiscloseInfoSettings() {
	const SETTINGS_KEYS = 'discloseInfo';

	return getSettings(SETTINGS_KEYS).then((res) => JSON.parse(res.discloseInfo));
}

export function editDiscloseInfoSettings(chapters) {
	return editSettings({ discloseInfo: JSON.stringify(chapters) });
}

export function getProjectStatusSettings() {
	const SETTINGS_KEYS = 'projectStatusSettings';

	return getSettings(SETTINGS_KEYS).then((res) => JSON.parse(res.projectStatusSettings));
}

export function editProjectStatusSettings(status) {
	return getProjectStatusSettings()
		.then(statuses => statuses.map(el => el.code === status.code ? status : el))
		.then((statuses) => editSettings({ projectStatusSettings: JSON.stringify(statuses) }));
}

export function getPitchStatusSettings() {
	const SETTINGS_KEYS = 'pitchStatusSettings';

	return getSettings(SETTINGS_KEYS).then((res) => JSON.parse(res.pitchStatusSettings));
}

export function editPitchStatusSettings(status) {
	return getPitchStatusSettings()
		.then(statuses => statuses.map(el => el.code === status.code ? status : el))
		.then((statuses) => editSettings({ pitchStatusSettings: JSON.stringify(statuses) }));
}

export function getParticipantStatusSettings() {
	const SETTINGS_KEYS = 'participantStatusSettings';

	return getSettings(SETTINGS_KEYS).then((res) => JSON.parse(res.participantStatusSettings));
}

export function editParticipantStatusSettings(status) {
	return getParticipantStatusSettings()
		.then(statuses => statuses.map(el => el.code === status.code ? status : el))
		.then((statuses) => editSettings({ participantStatusSettings: JSON.stringify(statuses) }));
}

export function getPartnersSettings() {
	const SETTINGS_KEYS = 'partners';

	return getSettings(SETTINGS_KEYS).then((res) => JSON.parse(res.partners));
}

export function editPartnersSettings(chapters) {
	return editSettings({ partners: JSON.stringify(chapters) });
}

export function getBannerSettings() {
	const SETTINGS_KEYS = 'banner';

	return getSettings(SETTINGS_KEYS).then((res) => JSON.parse(res.banner));
}

export function editBannerSettings(banner) {
	return getBannerSettings()
		.then(bannerSettings => bannerSettings.map(el => el.code === banner.code ? banner : el))
		.then((bannerSettings) => editSettings({ banner: JSON.stringify(bannerSettings) }));
}