import { postApiRequestBuilder, getApiRequestBuilder, putApiRequestBuilder, deleteApiRequestBuilder } from '@/utils/RequestBuilder';

export * as moderation from './moderation';
export * from './admin';

const END_POINT_ADD_PARTICIPANT = 'participants';
const CONFIRM_PHONE = 'user/confirm/CONFIRM_PHONE';
const CONFIRM_PHONE_CODE = 'user/confirm/CONFIRM_PHONE/code'
const CONFIRM_EMAIL = 'user/confirm/CONFIRM_EMAIL';
const CONFIRM_EMAIL_CODE = 'user/confirm/CONFIRM_EMAIL/code'
const GET_PHONE_CONFIRMATION_DATA = 'user/request/CONFIRM_PHONE'
const GET_EMAIL_CONFIRMATION_DATA = 'user/request/CONFIRM_EMAIL'
const CHANGE_USER_PASSWORD = 'user/password'
const END_POINT_SCORING = 'backoffice/api/scoring'

export async function addParticipant(participantForm) {
	return postApiRequestBuilder(`${END_POINT_ADD_PARTICIPANT}`).send(participantForm);
}

export function getUserParticipantsList() {
	return getApiRequestBuilder(`user/participants`).send()
}

export function getParticipantSettings(participantId) {
	return getApiRequestBuilder(`participants/${participantId}/settings`).send().then((res) => res.data.settings);
}

export function getParticipantData(ParticipantId) {
	return getApiRequestBuilder(`participants/${ParticipantId}`).send()
}

export function saveParticipantInfo(data) {
	return putApiRequestBuilder(`participants`).send(data)
}

export function saveDraftParticipant(questionary) {
	return postApiRequestBuilder(`${END_POINT_ADD_PARTICIPANT}/draft`).send(questionary);
}

export function editDraftParticipant(questionary) {
	return putApiRequestBuilder(`${END_POINT_ADD_PARTICIPANT}/draft`).send(questionary);
}

export function getParticipantAccounts(ParticipantId) {
	return getApiRequestBuilder(`participants/${ParticipantId}/accounts`).send()
}

export function getOperatorParticipantAccounts(ParticipantId) {
	return getApiRequestBuilder(`operator/participants/${ParticipantId}/investment-account`).send()
}

export function deleteParticipantAccountsDraft(ParticipantId) {
	return deleteApiRequestBuilder(`participants/${ParticipantId}`).send({ParticipantId});
}

export function moveToModeration(participantId) {
	return postApiRequestBuilder(`${END_POINT_ADD_PARTICIPANT}/${participantId}/register`).send();
}

export function moveToDraft(participantId) {
	return postApiRequestBuilder(`${END_POINT_ADD_PARTICIPANT}/${participantId}/draft`).send();
}

export function scoringParticipant(participantId,step) {
	return postApiRequestBuilder(`${END_POINT_SCORING}/${step}`).send(
		{
			id: participantId
		}
	);
}

export function getUser() {
	return getApiRequestBuilder(`user/data`).send();
}

export function changeUserPassword(passwordForm) {
	return postApiRequestBuilder(`${CHANGE_USER_PASSWORD}`)
		.send({
			oldPassword: passwordForm.oldPassword,
			password: passwordForm.password
		  })
}

export function postParticipantAppeal(participantId, appealText) {
	return postApiRequestBuilder(`participants/${participantId}/appeal`).send({
		participantId, appealText
	});
}

export function emailConfirmationCode({ requestId, code }) {
	return postApiRequestBuilder(`${CONFIRM_EMAIL_CODE}`).public.send({ requestId, code });
}

export function postEmailConfirmation(email) {
	return postApiRequestBuilder(`${CONFIRM_EMAIL}`).send(email)
}

export function postPhoneConfirmation(phone) {
	return postApiRequestBuilder(`${CONFIRM_PHONE}`)
		.send({ contact: phone.replace(/[^0-9]/g, '') })
		.catch(async (err) => {
			if (err.response.status === 400) {
				return getPhoneConfirmationData();
			}

			throw err;
		});
}

export function phoneConfirmationCode({ requestId, code }) {
	return postApiRequestBuilder(`${CONFIRM_PHONE_CODE}`).send({ requestId, code });
}

export function getPhoneConfirmationData() {
	return getApiRequestBuilder(`${GET_PHONE_CONFIRMATION_DATA}`).send();
}

export function getEmailConfirmationData() {
	return getApiRequestBuilder(`${GET_EMAIL_CONFIRMATION_DATA}`).send();
}

export function getRenderedTemplateParticipant(id) {
	return getApiRequestBuilder(`participants/${id}/render`).send();
}

export function investIntoProject(participantId, projectId, amount, additionalData = {}) {
	return postApiRequestBuilder(`participants/${participantId}/invest`)
		.send({
			projectId,
			amount,
			...additionalData,
		});
}

export function cancelInvestment(participantId, investmentId) {
	return deleteApiRequestBuilder(`participants/${participantId}/invest/${investmentId}/cancel`).send();
}

export function cancelInvestOperation(participantId, operationId) {
	return deleteApiRequestBuilder(`participants/${participantId}/invest/operations/${operationId}/cancel`).send();
}

export function topUpAccount(participantId, accountId, amount) {
	return putApiRequestBuilder(`participants/${participantId}/accounts/${accountId}/credit`).send({amount})
}

export async function getCreditBankDetails(participantId, accountId) {
	return postApiRequestBuilder(`participants/${participantId}/accounts/${accountId}/credit/details`)
		.send()
		.then((res) => res.data)
		.catch(() => null);
}

/**
 * 
 * @param {number} participantId 
 * @param {number} accountId 
 * @param {object} withdrawData 
 * @param {number} withdrawData.amount
 * @param {object} withdrawData.bankDetails
 * @param {string} withdrawData.bankDetails.bik
 * @param {string} withdrawData.bankDetails.account
 * @param {string} withdrawData.bankDetails.bankName
 * @param {string} withdrawData.bankDetails.correspondentAccount
 * @param {string} withdrawData.bankDetails.inn
 * @param {string} withdrawData.bankDetails.kpp
 * @param {string} withdrawData.bankDetails.payer
 * @returns {Promise<any>}
 */
export async function withdrawalAccount(participantId, accountId, withdrawData) {
	return putApiRequestBuilder(`participants/${participantId}/accounts/${accountId}/debit`)
		.send(withdrawData).then((res) => res.data);
}

export async function getWithdrawalFunds() {
	return getApiRequestBuilder(`operator/participants/withdrawal`)
		.send().then((res) => res.data);
}

export async function confirmWithdrawalRequest(requestId) {
	return putApiRequestBuilder(`operator/participants/withdrawal/${requestId}/approve`)
		.send();
}

export async function rejectWithdrawalRequest(requestId) {
	return putApiRequestBuilder(`operator/participants/withdrawal/${requestId}/reject`)
		.send();
}

export async function getLastDebitPayment(participantId, accountId) {
	return getApiRequestBuilder(`participants/${participantId}/accounts/${accountId}/debit`)
		.send()
		.then((res) => res.data)
		.catch(() => null);
}

export async function getParticipantsInvestSummary(participantId) {
	const query = [];
	if (participantId) {
		query.push(['participantId', participantId]);
	}

	return getApiRequestBuilder(`/participants/invest-summary`)
		.query(query)
		.send()
		.then((res) => res.data)
		.catch(() => null);
}

export async function getApproveHistory(participantId, query = []) {
	return getApiRequestBuilder(`participants/${participantId}/moderate/history`)
		.query(query)
		.send()
		.then((res) => res.data.items)
}

/**
 *
 * @param {Array} settings
 * @returns {Object}
 */
export function mapParticipantSettings(settings) {
	const KEYS = [
		'DISABLE_INTEREST_RATE_FORMULA',
		'DISABLE_TAX_FORMULA',
		'IGNORE_PROJECT_CONSTRAINTS',
	];

	return KEYS.reduce((acc, key) => {
		acc[key] = settings.find((setting) => setting.code === key)?.value === 'true';
		return acc;
	}, {});
}


export const PARTICIPANT_SETTINGS_LABELS = {
	DISABLE_TAX_FORMULA: 'Отключить формулу расчета комиссии платформы',
	DISABLE_INTEREST_RATE_FORMULA: 'Отключить формулу расчета процентной ставки',
	IGNORE_PROJECT_CONSTRAINTS: 'Игнорировать ограничения проекта',
}

export const PARTICIPANT_SETTINGS_CODES = {
	DISABLE_TAX_FORMULA: 'DISABLE_TAX_FORMULA',
	DISABLE_INTEREST_RATE_FORMULA: 'DISABLE_INTEREST_RATE_FORMULA',
	IGNORE_PROJECT_CONSTRAINTS: 'IGNORE_PROJECT_CONSTRAINTS',
}

export function serializeParticipantSettings(settings) {
	return Object.keys(settings).reduce((acc, key) => {
		acc.push({
			code: key,
			value: settings[key].toString(),
			label: PARTICIPANT_SETTINGS_LABELS[key],
		});
		return acc;
	}, []);
}