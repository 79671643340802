import axios from "axios";

const ENV_FILE = 'env.json';
const ENV_CONTAINER = {}

export const VUE_APP_SERVICE_NAME = 'vue_app_service_name';
export const VUE_APP_BASE_URL = 'vue_app_base_url';
export const VUE_APP_KEYCLOAK_URL = 'vue_app_keycloak_url';
export const VUE_APP_KEYCLOAK_REALM = 'vue_app_keycloak_realm';
export const VUE_APP_KEYCLOAK_CLIENT_ID = 'vue_app_keycloak_clientId';
export const VUE_APP_CHAT_BASE_URL = 'vue_app_chat_base_url';

export async function fetchEnv() {
	const conf =     {
		method: 'get',
		url: `/${ENV_FILE}`
	}
	return axios.request(conf).then(r => {

		return new Promise(resolve => {
			for (const key in r.data) {
				ENV_CONTAINER[key] = r.data[key];
				process.env[key] = r.data[key];
			}

			resolve();
		})
	});
}

export function getEnv(envKey) {
	if (!hasEnv(envKey)) {
		throw new Error('Environment ' + envKey + ' is not defined');
	}
	return ENV_CONTAINER[envKey]
}

export function hasEnv(envKey) {
	return ENV_CONTAINER[envKey] !== undefined && ENV_CONTAINER[envKey];
}