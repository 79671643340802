import { getUserParticipantsList } from '@services/participant';
import { createPromise, fio } from '@utils/common';
import {
	ENTITY_TYPES,
	OPF_CODES,
	ROLE_CODES,
	STATUS,
	LPI_RATINGS
} from '@configs/participants';

export const PARTICIPANT_SELECTOR_FILTERS = {
	any: 'any',
	investor: 'investor',
	founder: 'founder',
	ao: 'ao',
};

const ENTITY_TYPE_NAME = {
	INDIVIDUAL: 'ФЛ',
	INDIVIDUAL_ENTREPRENEUR: 'ИП',
	LEGAL_ENTITY: 'ЮЛ',
};

/**
 * @type {import('vuex').Module}
 */
export default {
	namespaced: true,
	state() {
		const { promise, resolve } = createPromise();

		return {
			loading: false,
			loadingPromise: promise,
			resolveLoadingPromise: resolve,
			entityList: [],
			selectorFilter: PARTICIPANT_SELECTOR_FILTERS.any,
		};
	},

	mutations: {
		setLoading(state, loading) {
			state.loading = loading;
		},
		setEntitiesList(state, list) {
			state.entityList = list;
		},
		setSelectorFilter(state, param) {
			state.selectorFilter = param;
		},
		setLoadingPromise(state, { promise, resolve }) {
			state.loadingPromise = promise;
			state.resolveLoadingPromise = resolve;
		},
	},

	actions: {
		setSelectorFilter({ commit }, filter) {
			if (Object.values(PARTICIPANT_SELECTOR_FILTERS).includes(filter)) {
				commit('setSelectorFilter', filter);
			} else {
				commit('setSelectorFilter', PARTICIPANT_SELECTOR_FILTERS.any);
			}
		},

		async loadEntitiesList(context) {
			context.commit('setLoadingPromise', createPromise());
			context.commit('setLoading', true);
			const res = await getUserParticipantsList();
			context.commit('setEntitiesList', res.data.entities);
			context.commit('setLoading', false);
			context.state.resolveLoadingPromise();
		},
	},

	getters: {
		getterLoading: (state) => state.loading,
		loadingPromise: (state) => state.loadingPromise,
		entities: (state) => state.entityList ?? [],
		participants: (state, getters) => {
			return getters.entities
				.map((entity) => [entity.founder, entity.investor])
				.flat()
				.filter(Boolean);
		},
		investorEntities: (state, getters) => {
			return getters.entities.filter((p) => p.investor);
		},
		hasInvestorEntitiesActive: (state, getters) => {
			return getters.investorEntities.some((p) => p.investor.status === STATUS.ACTIVE)
		},
		investors: (state, getters) => {
			return getters.investorEntities.map((p) => p.investor);
		},
		founderEntities: (state, getters) => {
			return getters.entities.filter((p) => p.founder);
		},
		founders: (state, getters) => {
			return getters.founderEntities.map((p) => p.founder);
		},
		participantsCount: (state, getters) => getters.participants?.length ?? 0,
		entitiesAO(state, getters) {
			return getters.entities.filter(
				(entity) => entity?.organizationOpf === OPF_CODES.ZAO || entity?.organizationOpf === OPF_CODES.OAO,
			);
		},
		participantsAO(state, getters) {
			return getters.entitiesAO
				.map((entity) => [entity.founder, entity.investor])
				.flat()
				.filter(Boolean);
		},
		foundersEntitiesAO(state, getters) {
			return getters.founderEntities.filter(
				(entity) => entity?.organizationOpf === OPF_CODES.ZAO || entity?.organizationOpf === OPF_CODES.OAO,
			);
		},
		activeParticipants(state, getters) {
			return getters.participants.filter(
				(entity) => entity?.status === STATUS.ACTIVE,
			);
		},
		activeParticipantsAO(state, getters) {
			return getters.participantsAO.filter((p) => p.status === STATUS.ACTIVE);
		},
		activeFoundersAO(state, getters) {
			return getters.activeParticipantsAO.filter(
				(p) => p.role === ROLE_CODES.FOUNDER,
			);
		},
		hasParticipantAO(state, getters) {
			return getters.participantsAO.length > 0;
		},
		hasActiveParticipantAO(state, getters) {
			return getters.activeParticipantsAO.length > 0;
		},
		activeFounders(state, getters) {
			return getters.activeParticipants.filter(
				(p) => p.role === ROLE_CODES.FOUNDER,
			);
		},
		hasActiveFounder(state, getters) {
			return getters.activeFounders.length > 0;
		},
		hasActiveFounderAO(state, getters) {
			return getters.activeFoundersAO.length > 0;
		},
		allParticipantIds(state, getters) {
			return getters.participants.map((p) => p.participantId);
		},
		filteredEntityList(state, getters) {
			switch (state.selectorFilter) {
			case PARTICIPANT_SELECTOR_FILTERS.investor:
				return getters.investorEntities;

			case PARTICIPANT_SELECTOR_FILTERS.founder:
				return getters.founderEntities;

			case PARTICIPANT_SELECTOR_FILTERS.ao:
				return getters.entitiesAO;

			default:
				return getters.entities;
			}
		},
		formattedFilteredEntityList(state, getters) {
			return getters.filteredEntityList.map(ent => ({
				...entityFormatter(ent),
				investor: ent.investor,
				founder: ent.founder,
			}));
		},
		formattedEntityList(state, getters) {
			return getters.entities.map(ent => ({
				...entityFormatter(ent),
				investor: ent.investor,
				founder: ent.founder,
			}));
		},
		hasEntityPerson(state, getters) {
			return getters.entities.some(ent => ent.entityType === ENTITY_TYPES.INDIVIDUAL);
		},
		getterEntityPerson(state, getters) {
			return getters.entities.find(ent => ent.entityType === ENTITY_TYPES.INDIVIDUAL);
		},
		filteredLPIList(state, getters) {
			switch (state.selectorFilter) {
			case PARTICIPANT_SELECTOR_FILTERS.ao:
				return getters.foundersEntitiesAO;

			default:
				return getters.founderEntities;
			}
		},
		formattedFilteredLPIList(state, getters) {
			return getters.filteredLPIList.map((ent) => ({
				...entityFormatter(ent),
				value: ent.founder.participantId,
				status: ent.founder.status,
				rating: ent.founder.rating,
			}));
		},
		formattedFilteredLoanLPIList(state, getters) {
			return getters.formattedFilteredLPIList.filter((lpi) => lpi.rating && lpi.rating.code !== LPI_RATINGS.NO_RATING);
		},
		formattedInvestorList(state, getters) {
			return getters.investorEntities.map((ent) => ({
				...entityFormatter(ent),
				value: ent.investor.participantId,
				status: ent.investor.status,
			}));
		},

		formattedFounderList(state) {
			let list = state.entityList.filter((participant) =>
				participant?.founder != null ? participant : undefined,
			);
			return list.map((participant) => {
				const name = /^INDIVIDUAL.*/.test(participant.entityType)
						? fio(
							participant.lastName,
							participant.firstName,
							participant.middleName,
							true,
						)
						: participant.organizationShortName,
					prefix =
            participant.entityType === ENTITY_TYPES.INDIVIDUAL_ENTREPRENEUR
            	? ENTITY_TYPE_NAME[participant.entityType] + ' '
            	: '';
				return {
					name: `${prefix}${name}\nИНН: ${participant.inn}`,
					label: `${prefix}${name}\nИНН: ${participant.inn}`,
					nameWithPrefix: `${prefix}${name}`,
					value: participant.founder.participantId,
					status: participant.founder.status,
					entityType: participant.entityType,
					inn: participant.inn,
				};
			});
		},
	},
};

function entityFormatter(entity) {
	const name = /^INDIVIDUAL.*/.test(entity.entityType)
		? fio(entity.lastName, entity.firstName, entity.middleName, true)
		: entity.organizationShortName;
	const prefix = entity.entityType === ENTITY_TYPES.INDIVIDUAL_ENTREPRENEUR
		? ENTITY_TYPE_NAME[entity.entityType] + ' '
		: '';

	return {
		label: `${prefix}${name}\nИНН: ${entity.inn ?? '-'}`,
		value: entity.userEntityId,
		nameWithPrefix: `${prefix}${name}`,
		name: `${prefix}${name}\nИНН: ${entity.inn ?? '-'}`,
		entityType: entity.entityType,
		inn: entity.inn,
	};
}
