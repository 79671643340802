import { INVEST_TYPES } from '@configs/investProject';
import { ROLE_CODES, ENTITY_TYPES } from '@configs/participants';

export const DELIMITER = '.';

export const CIPHER_PREFIXES = {
	'ЛПИ-ЮЛ': 'ЛПИ-ЮЛ',
	'ЛПИ-ИП': 'ЛПИ-ИП',
	'ИНВ-ЮЛ': 'ИНВ-ЮЛ',
	'ИНВ-ИП': 'ИНВ-ИП',
	'ИНВ-ФЛ': 'ИНВ-ФЛ',
	ПРП: 'ПРП',
	ЗАЙМ: 'ЗАЙМ',
	КЗМ: 'КЗМ',
	УЦП: 'УЦП',
	АКЦ: 'АКЦ',
};

export const CIPHER_PREFIXES_ARRAY = Object.keys(CIPHER_PREFIXES);

export const CIPHER_PARTICIPANT_ROLES = {
	[ROLE_CODES.INVESTOR]: 'ИНВ',
	[ROLE_CODES.FOUNDER]: 'ЛПИ',
};

export const CIPHER_PARTICIPANT_ENTITY_TYPES = {
	[ENTITY_TYPES.INDIVIDUAL]: 'ФЛ',
	[ENTITY_TYPES.INDIVIDUAL_ENTREPRENEUR]: 'ИП',
	[ENTITY_TYPES.LEGAL_ENTITY]: 'ЮЛ',
};

export const CIPHER_PROJECT_TYPES = {
	[INVEST_TYPES.STOCK]: CIPHER_PREFIXES.АКЦ,
	[INVEST_TYPES.LOAN]: CIPHER_PREFIXES.ЗАЙМ,
	[INVEST_TYPES.CONVERTIBLE_LOAN]: CIPHER_PREFIXES.КЗМ,
	[INVEST_TYPES.UCP]: CIPHER_PREFIXES.УЦП,
};

export const CIPHER_PARTICIPANT_PREFIXES = {
	'ЛПИ-ЮЛ': 'ЛПИ-ЮЛ',
	'ЛПИ-ИП': 'ЛПИ-ИП',
	'ИНВ-ЮЛ': 'ИНВ-ЮЛ',
	'ИНВ-ИП': 'ИНВ-ИП',
	'ИНВ-ФЛ': 'ИНВ-ФЛ',
};
