import Requests from '@utils/Requests.js';
import { getApiRequestBuilder, postApiRequestBuilder } from '@utils/RequestBuilder';
import api from '@configs/api.js';
import { getUTCOffset } from '@utils/common';
export * from './thirdParty';

export {
	renderTransferOrder,
	getCommonDocuments,
	createDocument,
	createCommonDocument,
	createDocumentTemplate,
	getTemplates,
	getRenderedTemplate,
	setStamp,
	fileToDocument,
	documentToFile,
	getDocumentDTO,
	resendDocument,
};
	
function renderTransferOrder(projectId, investorIdList) {
	return postApiRequestBuilder(`projects/${projectId}/shares-transfer-order-render`).responseType('blob').send({investorIdList: investorIdList});
}

function getCommonDocuments() {
	const END_POINT = 'documents/common';
	return Requests.requestWrapper(
		Requests.getRequest(`/${api.url}${END_POINT}`, {}, true)
	);
}

function getTemplates() {
	const END_POINT = 'documents/templates';
	return Requests.requestWrapper(
		Requests.getRequest(`/${api.url}${END_POINT}`, {}, true)
	);
}

function createDocument(doc) {
	const END_POINT = 'documents';
	return Requests.requestWrapper(
		Requests.postRequest(`/${api.url}${END_POINT}`, doc, {}, true)
	);
}

function createCommonDocument(doc) {
	const END_POINT = 'documents/common';
	return Requests.requestWrapper(
		Requests.postRequest(`/${api.url}${END_POINT}`, doc, {}, true)
	);
}

function createDocumentTemplate(template) {
	const END_POINT = 'documents/templates';

	return Requests.requestWrapper(
		Requests.postRequest(`/${api.url}${END_POINT}`, template, {}, true)
	);
}

async function getRenderedTemplate(code, dataKey, isTransient, outputFormat) {
	let query = [['dataKey', dataKey]];
	if (isTransient) {
		query.push(['isTransient', 'true']);
	}
	if (outputFormat) {
		query.push(['outputFormat', outputFormat]);
	}
	return getApiRequestBuilder(`documents/templates/render/${code}`)
		.query(query)
		.send();
}

async function setStamp(documentId) {
	const END_POINT = `documents/stamp/${documentId}?offset=${getUTCOffset()}`;
	const request = Requests.requestWrapper(
		Requests.getRequest(
			`/${api.url}${END_POINT}`,
			{ responseType: 'blob' },
			true
		)
	);
	return request;
}

async function getDocumentDTO(documentId) {
	const END_POINT = `documents/${documentId}`;
	return getApiRequestBuilder(END_POINT).send();
}

async function resendDocument(documentId) {
	const END_POINT = `sign/request/repeat`;
	return postApiRequestBuilder(END_POINT).query(Object.entries({ documentId })).send();
}

/**
 * Формирует объект документа по файлу (FileSpecs)
 * @param {
 *   documentType: string,
 *   documentName?: string,
 *   documentNumber?: string,
 *   documentDate?: Date,
 *   fileId?: number,
 *   link?: string,
 *   file: {
 *     name: string,
 *     type: string,
 *     size: number,
 *   },
 * } file параметры файла со значениями для формирования документа
 * @param {string} docTypeCode код типа документа; определяет или переопределяет (если код задан в file) код типа документа
 * @returns {
 *   typeCode: string,
 *   name?: string,
 *   number?: string,
 *   date?: Date,
 *   content: {
 *     fileId?: number,
 *     link?: string,
 *     file: {
 *       name: string,
 *       type: string,
 *       size: number,
 *     }
 *   }
 * }
 */
function fileToDocument(fileSpec) {
	if (!fileSpec) return undefined;
	return {
		typeCode: fileSpec.documentType,
		name: fileSpec.documentName,
		number: fileSpec.documentNumber,
		date: fileSpec.documentDate,
		documentId: fileSpec.documentId,
		access: fileSpec.documentAccess,
		scope: fileSpec.documentScope,
		content: {
			file: {
				name: fileSpec.file.name,
				type: fileSpec.file.type,
				size: fileSpec.file.size,
			},
			fileId: fileSpec.fileId,
			link: fileSpec.link,
		},
	};
}

/**
 * Формирует объект файла (FileSpecs) по документу (DocumentDto)
 * @param {
 *   typeCode: string,
 *   name?: string,
 *   number?: string,
 *   date?: Date,
 *   content: {
 *     fileId?: number,
 *     link?: string,
 *     file: {
 *       name: string,
 *       type: string,
 *       size: number,
 *     }
 *   }
 * } doc объект документа (DocumentDto)
 * @returns {
 *   documentType: string,
 *   documentName?: string,
 *   documentNumber?: string,
 *   documentDate?: Date,
 *   fileId?: number,
 *   link?: string,
 *   file: {
 *     name: string,
 *     type: string,
 *     size: number,
 *   },
 * }
 */
function documentToFile(doc) {
	if (!doc) return undefined;
	return {
		legalDoc: !!doc.legalDoc,
		documentType: doc.typeCode,
		documentName: doc.name,
		documentNumber: doc.number,
		documentDate: doc.date,
		documentId: doc.documentId,
		documentAccess: doc.access,
		documentScope: doc.scope,
		fileId: doc.content?.fileId,
		link: doc.content?.link,
		file: doc.content?.file,
	};
}
