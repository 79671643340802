<template>
	<div class="ui-document" :class="{ 'ui-document--wide': showResendButton }">
		<div class="ui-document--name">
			<a :href="href" target="_blank" class="black-text">{{ name }}</a>
		</div>
		<span
			v-if="finishedSing"
			class="status nw ui-document__chip"
			:style="{ backgroundColor: '#009C80' }"
		>
			Подписан
		</span>
		<span
			v-else
			class="status nw ui-document__chip"
			:style="{ backgroundColor: '#7D7D7D' }"
		>
			Не подписан
		</span>
		<div class="flex gap-16 flex-right">
			<UiButton
				v-if="showResendButton"
				:label="resendLabel"
				type="secondary"
				size="xs"
				:pending="resendPending"
				:disabled="alreadyResent"
				@click="handleResend"
			/>
		</div>
	</div>
</template>

<script>
import {SINGER_TYPES} from "@configs/participants";
import {resendDocument} from "@services/documents";
import {isDocumentSigned} from "@utils/documents";
import { DOCUMENT_SIGN_PAGE, DOCUMENT_SIGN_WATCH_PAGE } from '@/router/routes/names';
import { ERROR, SUCCESS } from '@/configs/ui';
import { INVEST_TYPES } from "@/configs/investProject";
import { mapGetters } from "vuex";

export default {
	name: "UiDocument",
	model: {
		prop: 'document',
		event: 'changeDocument'
	},
	props: {
		document: {
			type: Object,
			required: true,
		},
		participantId: {
			type: Number,
			required: true,
		},
		showFinishedOnlyParticipantSelf: {
			type: Boolean,
			default: false,
		},
		showFinishedOnlyEntitySelf: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		documentModel: undefined,
		broadcastChannel: new BroadcastChannel('app-document-sing'),
		alreadyResent: false,
		resendPending: false,
	}),
	computed: {
		...mapGetters({
			project: 'project/getterProject',
			collateralTypesSignedByThirdParty: "project/getterCollateralTypesSignedByThirdParty",
		}),
		name() {
			return this.documentModel.content.file.name;
		},
		finishedSing() {
			if (this.participantId && !this.needParticipantSing && this.showFinishedOnlyParticipantSelf) {
				return true;
			}
			if (this.showFinishedOnlyEntitySelf && !this.needEntitySing) {
				return true;
			}
			return isDocumentSigned(this.documentModel);
		},
		needParticipantSing() {
			return this.documentModel.signs.some(sing => {
				return sing.type === null && sing.signerType === SINGER_TYPES.PARTICIPANT && sing.signerId === this.participantId
			});
		},
		needEntitySing() {
			return this.documentModel.signs.some(sing => {
				return sing.type === null && sing.signerType === SINGER_TYPES.ENTITY
			});
		},
		showResendButton() {
			return !this.needParticipantSing && !this.readonly && !this.finishedSing;
		},
		resendLabel() {
			if (this.alreadyResent) {
				return 'Отправлено';
			}
			return 'Отправить заново';
		},
		signOrWatch() {
			return (this.needParticipantSing && !this.readonly);
		},
		href() {
			if (this.signOrWatch) {
				return this.$router.resolve({
					name: DOCUMENT_SIGN_PAGE,
					params: {
						participantId: this.participantId
					},
					query: {
						documentId: this.documentModel.documentId,
					}
				})?.href
			}
			return this.$router.resolve({
				name: DOCUMENT_SIGN_WATCH_PAGE,
				query: {
					documentId: this.documentModel.documentId,
				}
			})?.href
		},
		isStock() {
			return this.project.type === INVEST_TYPES.STOCK;
		},
		notifyText() {
			return this.isStock ? 'Акционерное соглашение' : 'Корпоративный договор';
		},

		notifyTextSuccesPart() {
			return this.isStock ? 'успешно отправлено' : 'успешно отправлен';
		},
	},
	watch: {
		document(document) {
			this.documentModel = document;
		},
	},
	beforeMount() {
		this.documentModel = this.document;
		this.broadcastChannel.addEventListener('message', this.broadcastChannelUpdate);
	},
	beforeDestroy() {
		this.broadcastChannel.removeEventListener('message', this.broadcastChannelUpdate);
	},
	methods: {
		sign() {
			window.open(this.href, '_blank');
		},
		async handleResend() {
			this.resendPending = true;
			try {
				await resendDocument(this.documentModel.documentId);
				this.alreadyResent = true;
				if (this.project.shareholders && this.project.shareholders.length || this.collateralTypesSignedByThirdParty && this.collateralTypesSignedByThirdParty.length) {
					this.notifySentEmails();
				} else {
					this.$notify({
						type: SUCCESS,
						text: `Документ "${this.documentModel.content.file.name}" успешно отправлен на подписание по указанному адресу электронной почты`,
					});
				}
			} catch (e) {
				console.error(e);
				this.$notify({
					type: ERROR,
					title: 'Не удалось отправить документ на подписание',
					text: e.response?.data.message,
				});
			} finally {
				this.resendPending = false;
			}
		},
		notifySentEmails() {
			this.project.shareholders?.forEach((shareholder) => {
				this.$notify({
					type: SUCCESS,
					text: `${this.notifyText} ${this.notifyTextSuccesPart} на подпись по указанному адресу электронной почты ${shareholder.email ?? shareholder.eio.email}`,
				});
			});
			this.collateralTypesSignedByThirdParty.forEach((collateral) => {
				this.$notify({
					type: SUCCESS,
					text: `Документ "${collateral.documents[0].typeName} №${collateral.documents[0].number}" успешно отправлен на подпись по указанному адресу электронной почты ${collateral.email ?? collateral.eio.email}`,
				});
			});
		},
		broadcastChannelUpdate(event) {
			if (event?.data?.documentId === this.documentModel.documentId) {
				this.$set(this, 'documentModel',  event.data);
				this.$emit('changeDocument', event.data);
			}
		}
	}
}
</script>